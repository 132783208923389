// prettier-ignore
export const {
    NODE_ENV,
    DEBUG,
    PUBLIC_URL,
    RECAPTCHA_KEY,
    API_ENDPOINT,
    GOOGLE_ANALYTICS_ID,
    SHOW_UNFINISHED_FEATURES,
    COMPLIANCE_DASHBOARD_ENABLED,
    EMAIL_CAMPAIGNS,
    COMMON_API_KEY,
    VALID_EMAIL,
    VALID_PASSWORD,
    DISABLE_WORKERS,
    DISABLE_CAPTCHA,
    EMERGENCY_MESSAGE,
    API_API_ORIGIN,
} = process.env;

export const isDebug = `${DEBUG}`.toLowerCase() === "true";
export const isProduction = `${NODE_ENV}`.toLowerCase() === "production";
export const isDevelopment = `${NODE_ENV}`.toLowerCase() === "development";
export const shouldShowUnfinishedFeatures = `${SHOW_UNFINISHED_FEATURES}`.toLowerCase() === "true";
export const emailCampaigns = JSON.parse(EMAIL_CAMPAIGNS || "{}");
export const commonApiKey = COMMON_API_KEY;
export const shouldShowComplianceDashboard = `${COMPLIANCE_DASHBOARD_ENABLED}`.toLowerCase() === "true";
export const shouldDisableWorkers = `${DISABLE_WORKERS}`.toLowerCase() === "true";
export const shouldDisableCaptcha = `${DISABLE_CAPTCHA}`.toLowerCase() === "true";
export const emergencyMessage = EMERGENCY_MESSAGE;
