import React, { useEffect } from "react";
import { Route, Routes, useParams, useLocation } from "react-router-dom";

import Page, { Title } from "@components/containers/Page";
import { Link, Icon } from "@components/common";

import Overview from "./Overview";
import NavHistory from "./NavHistory";
import Distribution from "./Distribution";
import Documents from "./Documents";
import "./ShareClassDetails.scss";
import Navigation from "@components/common/Navigation";
import { toTop } from "@src/common/util";

const baseClass = "acl-page-share-class";

type RouteParams = {
    shareClassId: string;
    [key: string]: string;
};

const ShareClassDetailsComponent: React.FC = () => {
    const { isin } = useParams<RouteParams>();
    const location = useLocation();

    const routes = [
        { label: "Overview", to: `/share-classes/${isin}/overview`, isActive: location.pathname === "/overview" },
        { label: "Documents", to: `/share-classes/${isin}/documents`, isActive: location.pathname === "/documents" },
        { label: "NAV History", to: `/share-classes/${isin}/nav-history`, isActive: location.pathname === "/nav-history" },
        { label: "Distribution", to: `/share-classes/${isin}/distribution`, isActive: location.pathname === "/distribution" },
    ];

    useEffect(() => {
        toTop();
    }, []);

    return (
        <Page className={baseClass}>
            <Link to="/funds" className={`${baseClass}__link`}>
                <Icon name="keyboard-arrow-left" spacing="right" />
                <span>Back to Fund Universe</span>
            </Link>
            <Title className={`${baseClass}__title`}>
                Share Class Details: <span className={`${baseClass}__isin`}>{isin}</span>
            </Title>
            <Navigation routes={routes} />
            <Routes>
                <Route path={`/overview`} element={<Overview isin={isin} />} />
                <Route path={`/documents`} element={<Documents isin={isin} />} />
                <Route path={`/nav-history`} element={<NavHistory isin={isin} />} />
                <Route path={`/distribution`} element={<Distribution isin={isin} />} />
            </Routes>
        </Page>
    );
};

export default ShareClassDetailsComponent;
