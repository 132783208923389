import React, { ReactNode } from "react";
import classNames from "classnames";

import "./Page.scss";

const baseClass = "acl-page";

interface IProps {
    className?: string;
    children?: ReactNode;
}

export const Title: React.FC<IProps> = React.memo(({ children, className }) => <h1 className={classNames(`${baseClass}__title`, className)}>{children}</h1>);

const Page: React.FC<IProps> = ({ children, className }) => <main className={classNames(baseClass, className)}>{children}</main>;

export default Page;
