import { UploadFileInfo } from "@progress/kendo-react-upload";

export enum FundType {
    UMBRELLA = "Umbrella",
    SINGLE_FUND = "Single Fund",
    SUBFUND = "Subfund",
    SHARECLASS = "Share Class",
}

export type FundFile = {
    size?: number;
    name?: string;
    field?: string;
    _id?: string;
    rlsWizardId?: string;
    fundId?: string;
    externalId?: string;
    buffer?: File;
    uploadFileInfo?: UploadFileInfo;
    companyId?: string;
    docType?: string;
};

export type UmbrellaFiles = {
    latestProspectusFiles: FundFile[];
    latestAnnualReportFiles: FundFile[];
    latestSemiAnnualReportFiles: FundFile[];
    articlesOfAssociationFiles: FundFile[];
    authorizedSignatoriesFiles: FundFile[];
    ucitsAttestationFiles: FundFile[];
};

export type UmbrellaFund = {
    domicile: string;
    ucits: boolean;
    inceptionDate: string;
    fiscalYearEnd: string;
    isRegulated: boolean;
    regulatorName: string;
    fundOfferingToBoth: boolean;
    files: UmbrellaFiles;
};
export type SubFundFiles = {
    prospectusSupplementFiles: FundFile[];
    marketingFiles: FundFile[];
    priipsKidsFiles: FundFile[];
};
export type SubFund = {
    authorizationDate: string;
    files: SubFundFiles;
};

export type SingleFundFiles = {
    latestProspectusFiles: FundFile[];
    latestAnnualReportFiles: FundFile[];
    latestSemiAnnualReportFiles: FundFile[];
    articlesOfAssociationFiles: FundFile[];
    authorizedSignatoriesFiles: FundFile[];
    marketingFiles: FundFile[];
    priipsKidsFiles: FundFile[];
    ucitsAttestatioinFiles: FundFile[];
};

export type SingleFund = {
    domicile: string;
    ucits: boolean;
    inceptionDate: string;
    fiscalYearEnd: string;
    isRegulated: boolean;
    fundOfferingToBoth: boolean;
    regulatorName: string;
    authorizationDate: string;
    files: SingleFundFiles;
};
export const initialUmbrellaFund: UmbrellaFund = {
    domicile: "",
    ucits: false,
    inceptionDate: null,
    fiscalYearEnd: null,
    isRegulated: false,
    regulatorName: "",
    fundOfferingToBoth: false,
    files: {
        articlesOfAssociationFiles: [],
        authorizedSignatoriesFiles: [],
        latestAnnualReportFiles: [],
        latestProspectusFiles: [],
        latestSemiAnnualReportFiles: [],
        ucitsAttestationFiles: [],
    },
};

export const initialSubFund: SubFund = {
    authorizationDate: null,
    files: {
        marketingFiles: [],
        priipsKidsFiles: [],
        prospectusSupplementFiles: [],
    },
};

export const initialSingleFund: SingleFund = {
    domicile: "",
    ucits: false,
    inceptionDate: null,
    fiscalYearEnd: null,
    isRegulated: false,
    fundOfferingToBoth: false,
    regulatorName: "",
    authorizationDate: null,
    files: {
        articlesOfAssociationFiles: [],
        authorizedSignatoriesFiles: [],
        latestAnnualReportFiles: [],
        latestProspectusFiles: [],
        latestSemiAnnualReportFiles: [],
        marketingFiles: [],
        priipsKidsFiles: [],
        ucitsAttestatioinFiles: [],
    },
};

export type Fund = {
    id: string;
    name: string;
    fundType: FundType;
    details?: UmbrellaFund | SubFund | SingleFund;
    shouldDelete?: boolean;
    children?: Fund[];
    parentId?: number;
};

export type FormFieldValue = string | boolean | Array<object>;
