import React from "react";
import style from "./Rtf.scss";
import classNames from "classnames";

type Props = {
    message: string | TrustedHTML;
    customClass?: string;
};

const Rtf: React.FC<Props> = ({ message, customClass }) => <div className={classNames(style.rtf, customClass)} dangerouslySetInnerHTML={{ __html: message }} />;

export default Rtf;
