import { ContactForms, DistribitionForms, DistrinutionFormDetails, FormDetails, InitialForms, StepAgentForm, StepAgentFormDetails } from "./Steps/types";

export const distrinutionFormDetails: DistrinutionFormDetails = {
    fullName: "Name",
    fullAddress: "Full Address",
    website: "Website",
};

export const initialDistributionFields: DistribitionForms = {
    form: {
        fullName: "",
        fullAddress: "",
        website: "",
    },
    fundProvider: null,
    authorized: null,
    distributed: null,
};

export const stepAgentFormDetails: StepAgentFormDetails = {
    payingAgentName: "Paying agent name",
    person: "Person",
    phone: "Phone",
    email: "Email",
    address: "Address",
};

export const initialStepAgentFields: StepAgentForm = {
    form: {
        payingAgentName: "",
        person: "",
        phone: "",
        email: "",
        address: "",
    },
    selected: false,
    selectedAgent: "",
};

export const formDetails: FormDetails = {
    main: {
        label: "Main responsible person for the overall relationship with Acolin",
        order: 1,
    },
    billing: {
        label: "Contact person for billing",
        order: 2,
    },
    legal: {
        label: "Legal contact person (for the agreements)",
        order: 3,
    },
    compliance: {
        label: "Compliance contact person (for the DDQ)",
        order: 4,
    },
    data: {
        label: "Data contact person (for BDT handling)",
        order: 5,
    },
};

export const initialForms: InitialForms = {
    main: {
        fullName: "",
        phone: "",
        email: "",
    },
    billing: {
        fullName: "",
        phone: "",
        email: "",
        ccemail: "",
    },
    legal: {
        fullName: "",
        phone: "",
        email: "",
    },
    compliance: {
        fullName: "",
        phone: "",
        email: "",
    },
    data: {
        fullName: "",
        phone: "",
        email: "",
    },
};

export const initialFormDetails: ContactForms = {
    main: {
        title: "Main responsible person for the overall relationship with Acolin",
        order: 1,
        labels: {
            fullName: "Full Name",
            phone: "Phone",
            email: "Email",
        },
    },
    billing: {
        title: "Contact person for billing",
        order: 2,
        labels: {
            fullName: "Full Name",
            phone: "Phone",
            email: "Email",
            ccemail: "CC Email",
        },
    },
    legal: {
        title: "Legal contact person (for the agreements)",
        order: 3,
        labels: {
            fullName: "Full Name",
            phone: "Phone",
            email: "Email",
        },
    },
    compliance: {
        title: "Compliance contact person (for the DDQ)",
        order: 4,
        labels: {
            fullName: "Full Name",
            phone: "Phone",
            email: "Email",
        },
    },
    data: {
        title: "Data contact person (for BDT handling)",
        order: 5,
        labels: {
            fullName: "Full Name",
            phone: "Phone",
            email: "Email",
        },
    },
};
