import { IDate } from "@src/common/types";

export interface ILoad {
    id: number;
    createdByUserId: string;
    fileName: string;
    validFrom: string;
    loadDate: string;
    lastUpdate: string;
    status: string;
}

export interface IFundGroup {
    id: number;
    name: string;
}

export interface IState {
    quarters: IDate[];
    months: IDate[];
    selectedQuarter: IDate;
    selectedMonth: IDate;
    fundGroups: IFundGroup[];
    selectedFundGroupUpload: IFundGroup;
    selectedFundGroupDownload: IFundGroup;
    uploadUrl: string;
    xlsxUploadUrl: string;
    shouldShowFundGroups: boolean;
}

export const initialState: IState = {
    quarters: [],
    selectedQuarter: undefined,
    months: [],
    selectedMonth: undefined,
    fundGroups: [],
    selectedFundGroupUpload: null,
    selectedFundGroupDownload: null,
    uploadUrl: null,
    xlsxUploadUrl: null,
    shouldShowFundGroups: false,
};
