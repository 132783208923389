import React from "react";
import { Card, CardContent } from "@components/common";
import "../InitiateOnboarding.scss";

const baseClass = "acl-page-initiate-onboarding";

const StepConfirmation: React.FC = () => (
    <Card className={`${baseClass}__overview`}>
        <CardContent className={`${baseClass}__success-wrapper`}>
            <div className={`${baseClass}__success-message`}>
                <p>Onboarding Request Submitted Successfully</p>
                <p>Our Fund Regulation Department will respond to your request as soon as the submitted information is reviewed.</p>
                <p>
                    For any questions, reach out to us at <a href="mailto:fundsregulationsch@acolin.com">fundsregulationsch@acolin.com.</a>
                </p>
            </div>
        </CardContent>
    </Card>
);

export default StepConfirmation;
