import React from "react";

import { DropDownList } from "@progress/kendo-react-dropdowns";

import "./DropdownFilterCell.scss";
interface IProps {
    value?: string;
    defaultItem: string;
    data: string[];
    onChange?({ value, operator, syntheticEvent }): void;
}

const DropdownFilterCell: React.FC<IProps> = props => {
    let hasValue = (value: string): boolean => Boolean(value && value !== props.defaultItem);

    const onChange = (event): void => {
        hasValue = (): boolean => hasValue(event.target.value);
        const isDefault = event.target.value === props.defaultItem;
        props.onChange({
            value: hasValue && !isDefault ? event.target.value : "",
            operator: hasValue && !isDefault ? "eq" : "",
            syntheticEvent: event.syntheticEvent,
        });
    };

    return (
        <div className="k-filtercell dropdown-filter-cell" data-testid="dropdown-list">
            <DropDownList data={props.data} onChange={onChange} value={props.value || props.defaultItem} defaultItem={props.defaultItem} />
        </div>
    );
};

export default DropdownFilterCell;
