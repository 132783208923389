import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./Icon.scss";

const baseClass = "acl-icon";

type IconSizes = "small" | "normal" | "large";

type IconSpacing = "left" | "right" | "both";

type IconAlignment = "top" | "middle" | "bottom";

interface IProps {
    name?: string;
    size?: IconSizes;
    title?: string;
    color?: string;
    inline?: boolean;
    spacing?: IconSpacing;
    align?: IconAlignment;
    className?: string;
    children?: ReactNode;
    onClick?(event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>): void;
}

const Icon: React.FC<IProps> = props => {
    const { name, size, title, color, inline, spacing, align, className, children, onClick } = props;

    const classes = classNames(
        baseClass,
        name && (name === "loading" ? "k-i-loading" : `acl-i-${name}`),
        size && `${baseClass}--${size}`,
        spacing && `${baseClass}--${spacing}`,
        align && `${baseClass}--${align}`,
        inline && `${baseClass}--inline`,
        onClick && `${baseClass}--clickable`,
        className,
    );

    const style = color && { color: styles[`color-${color}`] };

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => onClick && onClick(event);

    return (
        <i data-icon={name} title={title} role="img" aria-hidden="true" className={classes} style={style} onClick={handleClick}>
            {children}
        </i>
    );
};

export default Icon;
