import React from "react";
import classNames from "classnames";

import { Tooltip } from "react-tooltip";

import "./ContactInfo.scss";
import Link from "../Link";
import ContactImage from "../ContactImage";

const baseClass = "acl-contact";

interface IProps {
    title?: React.ReactNode;
    fullName: string;
    email: string;
    phoneNumber: string;
    className?: string;
    contactPersonWidth?: string;
}

const defaultTitle = <>Your Contact Person</>;

const ContactInfo: React.FC<IProps> = props => {
    const { title = defaultTitle, fullName, email, phoneNumber, className, contactPersonWidth = "5rem" } = props;
    const classes: string = classNames(baseClass, className);

    return (
        <div className={classes}>
            <h3 className={`${baseClass}__headline`}>{title}</h3>
            <ContactImage fullName={fullName} email={email} width={contactPersonWidth} />
            <address className={`${baseClass}__address`}>
                <strong className={`${baseClass}__name`}>{fullName}</strong>
                <div className={`${baseClass}__actions`}>
                    <Link
                        className={`${baseClass}__action`}
                        shape="round"
                        icon="email-action-unread"
                        to={`mailto:${email}`}
                        data-tooltip-id="userTooltip"
                        data-tooltip-content={email}
                    >
                        Email
                    </Link>
                    {phoneNumber && (
                        <>
                            <Link
                                className={`${baseClass}__action`}
                                shape="round"
                                icon="phone"
                                to={`tel:${phoneNumber}`}
                                data-tooltip-id="userTooltip"
                                data-tooltip-content={phoneNumber}
                            >
                                Phone number
                            </Link>
                        </>
                    )}
                </div>
            </address>
            <Tooltip id="userTooltip" place="bottom" delayShow={100} />
        </div>
    );
};

export default React.memo(ContactInfo);
