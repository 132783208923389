import React, { useState, useMemo, useEffect } from "react";
import { publish } from "pubsub-js";
import { useNavigate } from "react-router-dom";
import groupBy from "lodash/groupBy";
import { useLazyQuery } from "@apollo/client";

import {
    TreeList,
    TreeListExpandChangeEvent,
    TreeListRowClickEvent,
    TreeListRowProps,
    TreeListCellProps,
    filterBy,
    TreeListTextFilter,
    TreeListFilterChangeEvent,
} from "@progress/kendo-react-treelist";
import { Button, Chip } from "@progress/kendo-react-buttons";

import { useAppUserContext } from "@src/common/Context";
import Page, { Title } from "@components/containers/Page";
import { BackToTop, LoadingPanel, Card, CardHeader, CardContent } from "@components/common";
import ResponsiveTable from "@components/common/ResponsiveTable";
import { lifecycleStatusMap } from "@src/common/consts";

import "./Funds.scss";
import classNames from "classnames";
import { GET_ALL_FUNDS } from "@src/common/graphql";
import { errors } from "@src/common/errors";
import { PSChannel } from "@src/common/types";

const baseClass = "acl-page-funds";

type ShareClass = {
    id: number;
    fundId: number;
    isin: string;
    fullShareClassName: string;
    lifecycle: string;
    acolinRelevant: string;
    nav: number;
    navInfo: string;
    navCurrency: string;
    navDate: string;
};

type BaseFund = {
    id: number;
    name: string;
    fundName: string;
    cooperationPartnerId: number;
    managingCompanyId: number;
    domicileCountry: string;
    legalForm: string;
    active: boolean;
};

type Subfund = BaseFund & {
    parentId: number;
    fundType: "Subfund";
};

type UmbrellaFund = BaseFund & {
    fundType: "Umbrella";
};

type SingleFund = BaseFund & {
    fundType: "Single Fund";
};

type TopLevelFund = SingleFund | UmbrellaFund;

type Fund = TopLevelFund | Subfund;

/**
 *  Creates tree structure for kendo's TreeListView by adding the appropriate `treeListChildren` property to each fund.
 */
export function buildTreeListDataset(funds: Fund[], subfunds: Subfund[], shareClasses: ShareClass[]): Fund[] {
    const shareClassesFor: Record<number, ShareClass[]> = groupBy(shareClasses, "fundId");

    const treeListSubfunds: Subfund[] = subfunds.map(subfund => ({
        ...subfund,
        get treeListChildren(): ShareClass[] {
            return shareClassesFor[subfund.id];
        },
    }));

    const subfundsFor: Record<number, Fund[]> = groupBy(treeListSubfunds, "parentId");

    const treeListFunds: Fund[] = funds.map(fund => {
        switch (fund.fundType) {
            case "Single Fund":
                return {
                    ...fund,
                    get treeListChildren(): ShareClass[] {
                        return shareClassesFor[fund.id];
                    },
                };
            case "Umbrella":
                return {
                    ...fund,
                    get treeListChildren(): Fund[] {
                        return subfundsFor[fund.id];
                    },
                };
            default:
                return fund;
        }
    });

    return treeListFunds;
}

type TreeListState = { treeListExpanded: boolean };
type SubfundState = (Subfund & TreeListState)[];
type SingleFundState = SingleFund & TreeListState;
type UmbrellaFundState = UmbrellaFund & TreeListState;
type TopLevelFundState = (SingleFundState | UmbrellaFundState)[];

export const AcolinTreeListRow: React.FC<TreeListRowProps> = props => {
    if (props.dataItem.fullShareClassName) {
        return (
            <tr onClick={props.onClick} className={`${baseClass}__tree-list-isin-row`}>
                {props.children}
            </tr>
        );
    } else {
        return <tr className={`${baseClass}__tree-list-fund-row level-${props.level.length}`}>{props.children}</tr>;
    }
};

export const LifecycleCell: React.FC<TreeListCellProps> = ({ dataItem: { lifecycle } }) => (
    <td>
        {lifecycle && (
            <Chip
                text={lifecycle[0].toUpperCase() + lifecycle.substring(1)}
                value="chip"
                fillMode={"solid"}
                themeColor={lifecycleStatusMap(lifecycle)}
                className={classNames(`${baseClass}__lifecycle`, `${baseClass}__lifecycle--${lifecycleStatusMap(lifecycle)}`)}
            />
        )}
    </td>
);

const columns = [
    { field: "name", title: "Fund Name", expandable: true, filter: TreeListTextFilter },
    { field: "isin", title: "ISIN", filter: TreeListTextFilter },
    { field: "lifecycle", title: "Lifecycle", cell: LifecycleCell, width: 200, filter: TreeListTextFilter },
    { field: "navInfo", title: "Net Asset Value", width: 220, filter: TreeListTextFilter },
    { field: "acolinRelevant", title: "ISIN with Acolin Service", filter: TreeListTextFilter },
];

const Funds: React.FC<{}> = () => {
    const [appUser] = useAppUserContext();
    const [filter, setFilter] = useState([]);
    const [funds, setFunds] = useState<TopLevelFundState>([]);
    const [subfunds, setSubfunds] = useState<SubfundState>([]);
    const [shareClasses, setShareClasses] = useState<ShareClass[]>([]);

    const navigate = useNavigate();

    const [getAllFunds, { loading, error, data }] = useLazyQuery(GET_ALL_FUNDS, {
        variables: { companyId: appUser.companyId },
        fetchPolicy: "cache-first",
        onCompleted: data => {
            const { funds, subfunds, shareClasses } = data?.allFunds;
            const fundsWithTreeListState = funds?.map(fund => ({ ...fund, treeListExpanded: true })).sort((a, b) => a.name?.localeCompare(b.name));
            setFunds(fundsWithTreeListState ?? []);
            const subfundsWithTreeListState = subfunds?.map(subfund => ({ ...subfund, treeListExpanded: true })).sort((a, b) => a.name?.localeCompare(b.name));
            setSubfunds(subfundsWithTreeListState ?? []);
            const sortedShareClasses = [...shareClasses].sort((a, b) => a.name?.localeCompare(b.name));
            setShareClasses(sortedShareClasses ?? []);
        },
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }
        getAllFunds().catch(error => console.error(error));
    }, []);

    const treeListDataset = useMemo(() => buildTreeListDataset(funds, subfunds, shareClasses), [funds, subfunds, shareClasses]);

    const collapseAllSubfunds = (): void => {
        setFunds(funds =>
            funds.map(fund => {
                if (fund.fundType === "Single Fund") {
                    return {
                        ...fund,
                        treeListExpanded: false,
                    };
                } else {
                    return fund;
                }
            }),
        );
        setSubfunds(subfunds =>
            subfunds.map(subfund => ({
                ...subfund,
                treeListExpanded: false,
            })),
        );
    };

    function handleExpandChange(e: TreeListExpandChangeEvent): void {
        if (e.level.length === 2) {
            const toggledSubfund: Subfund = e.dataItem;
            setSubfunds(subfunds =>
                subfunds.map(subfund => {
                    if (subfund.id === toggledSubfund.id) {
                        return {
                            ...subfund,
                            treeListExpanded: !subfund.treeListExpanded,
                        };
                    } else {
                        return subfund;
                    }
                }),
            );
        } else if (e.level.length === 1) {
            const toggledFund: TopLevelFund = e.dataItem; // as TopLevelFund;
            setFunds(funds =>
                funds.map(fund => {
                    if (fund.id === toggledFund.id) {
                        return {
                            ...fund,
                            treeListExpanded: !fund.treeListExpanded,
                        };
                    } else {
                        return fund;
                    }
                }),
            );
        }
    }

    function handleRowClick(e: TreeListRowClickEvent): void {
        if (e.dataItem.isin) {
            const shareClass: ShareClass = e.dataItem;
            navigate(`/share-classes/${shareClass.isin}/overview`);
        }
    }

    const handleFilterChange = (event: TreeListFilterChangeEvent): void => {
        setFilter(event.filter);
    };

    const processData = (): Fund[] => {
        const filteredData = filterBy(treeListDataset, filter, "treeListChildren");
        return filteredData;
    };

    return (
        <Page className={baseClass}>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>Funds</Title>
            </header>
            <Card>
                {loading ? (
                    <div className={`${baseClass}__loading-wrapper`}>
                        <LoadingPanel />
                    </div>
                ) : (
                    <>
                        <CardHeader className={`${baseClass}__card-header`}>
                            <h3 className={`${baseClass}__title`}>Fund Universe</h3>
                            <Button onClick={collapseAllSubfunds}>Collapse all</Button>
                        </CardHeader>
                        <CardContent>
                            <ResponsiveTable>
                                <TreeList
                                    data={processData()}
                                    expandField={"treeListExpanded"}
                                    subItemsField={"treeListChildren"}
                                    onExpandChange={handleExpandChange}
                                    onRowClick={handleRowClick}
                                    row={AcolinTreeListRow}
                                    columns={columns}
                                    filter={filter}
                                    onFilterChange={handleFilterChange}
                                />
                            </ResponsiveTable>
                        </CardContent>
                    </>
                )}
            </Card>
            <BackToTop />
        </Page>
    );
};

export default Funds;
