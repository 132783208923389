import React from "react";

import { Form, FormElement, Field, FormRenderProps } from "@progress/kendo-react-form";
import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

import { requiredValidator, emailValidator } from "@src/common/util";
import { ERole, IUser } from "@src/common/types";
import LabelMultiselect from "@components/common/LabelMultipleSelect";
import LabelInput from "@components/common/LabelInput";
import { Icon } from "@components/common";

import "./UserForm.scss";

const baseClass = "acl-user-form";

interface IProps {
    user?: IUser;
    isDisabled?: boolean;
    onClose(event: DialogCloseEvent): void;
    onEdit?(roles: string[]): void; // eslint-disable-line
    onCreate?(user: IUser): void;
}

const initialValues: IUser = {
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    roles: [],
};

const roles = [
    { name: "Admin", code: ERole.PortalAdmin },
    { name: "Viewer", code: ERole.PortalViewer },
    { name: "Data Manager", code: ERole.DataManager },
    { name: "Document Manager", code: ERole.DocumentManager },
    { name: "Commission Manager", code: ERole.CommissionManager },
];

interface IRole {
    code: string;
    name: string;
}

export const mapUserRoles = (user: IUser): IRole[] =>
    user.roles.map(role => ({ ...role, name: role.name.replace("Portal ", "") })).sort((a, b) => (a.name > b.name ? 1 : -1));

const UserForm: React.FC<IProps> = ({ user, isDisabled, onEdit, onCreate, onClose = (): void => undefined }) => {
    const transformedUser: IUser = user
        ? {
              ...user,
              roles: mapUserRoles(user),
          }
        : null;

    const handleEditRoles = (dataItem: IUser): void => {
        const roles = dataItem?.roles?.map(role => role.code) || [];
        onEdit(roles);
    };

    const onCloseDialog = (event: DialogCloseEvent): void => onClose(event);

    const title = transformedUser
        ? `Edit roles for user ${transformedUser.firstName ? transformedUser.firstName : ""} ${transformedUser.lastName ? transformedUser.lastName : ""}`
        : "Request New User";

    const sortedRoles = roles.sort((a, b) => (a.name > b.name ? 1 : -1));

    return (
        <Dialog className={`${baseClass}`} title={title} onClose={onCloseDialog}>
            {!transformedUser ? (
                <Form
                    initialValues={initialValues}
                    onSubmit={onCreate}
                    render={(formRenderProps: FormRenderProps): React.ReactElement => (
                        <FormElement className={`${baseClass}__form`} noValidate={true}>
                            <div className={`${baseClass}__field-group`}>
                                <Field
                                    name="firstName"
                                    label="First Name"
                                    id="txtFirstName"
                                    ariaDescribedBy="txtNameError"
                                    component={LabelInput}
                                    required={true}
                                    className={`${baseClass}__field`}
                                    validator={requiredValidator}
                                />
                                <Field
                                    name="lastName"
                                    label="Family Name"
                                    id="txtLastName"
                                    ariaDescribedBy="txtLastNameError"
                                    component={LabelInput}
                                    required={true}
                                    className={`${baseClass}__field`}
                                    validator={requiredValidator}
                                />
                            </div>
                            <Field
                                type="email"
                                name="email"
                                label="Email Address"
                                id="txtEmail"
                                ariaDescribedBy="txtEmailError"
                                component={LabelInput}
                                required={true}
                                className={`${baseClass}__field`}
                                validator={[requiredValidator, emailValidator]}
                            />
                            <Field
                                name="jobTitle"
                                label="Position"
                                id="txtPosition"
                                ariaDescribedBy="txtPositionError"
                                component={LabelInput}
                                required={true}
                                className={`${baseClass}__field`}
                                validator={requiredValidator}
                            />
                            <Field
                                name="roles"
                                label="Assign Roles"
                                id="txtroles"
                                data={sortedRoles}
                                textField="name"
                                dataItemKey="code"
                                ariaDescribedBy="txtrolesError"
                                component={LabelMultiselect}
                                required={true}
                                className={`${baseClass}__field`}
                                validator={requiredValidator}
                            />
                            <div className={`k-form-buttons ${baseClass}__actions`}>
                                <Button themeColor="primary" type="submit" disabled={!formRenderProps.allowSubmit || isDisabled}>
                                    {isDisabled && <Icon name="loading" spacing="right" />}
                                    Submit Request
                                </Button>
                            </div>
                        </FormElement>
                    )}
                />
            ) : (
                <Form
                    initialValues={transformedUser}
                    onSubmit={handleEditRoles}
                    render={(formRenderProps: FormRenderProps): React.ReactElement => (
                        <FormElement className={`${baseClass}__form`} noValidate={true}>
                            <Field
                                name="roles"
                                label="Assign Roles"
                                id="txtroles"
                                data={sortedRoles}
                                textField="name"
                                dataItemKey="code"
                                ariaDescribedBy="txtrolesError"
                                component={LabelMultiselect}
                                required={false}
                                className={`${baseClass}__field`}
                            />
                            <div className={`k-form-buttons ${baseClass}__actions`}>
                                <Button themeColor="primary" type="submit" disabled={!formRenderProps.allowSubmit || isDisabled}>
                                    {isDisabled && <Icon name="loading" spacing="right" />}
                                    Apply
                                </Button>
                            </div>
                        </FormElement>
                    )}
                />
            )}
        </Dialog>
    );
};

export default UserForm;
