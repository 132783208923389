import React, { useEffect, useState, useRef } from "react";
import isEmpty from "lodash/isEmpty";
import { NetworkStatus, useQuery } from "@apollo/client";

import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Button } from "@progress/kendo-react-buttons";

import { LoadingPanel, Icon } from "@components/common";
import ResponsiveTable from "@components/common/ResponsiveTable";

import { IValidationStatus, Errors, ReportType } from "../types";
import "./ValidationReport.scss";
import { GET_VALIDATION_REPORT } from "@src/common/graphql";

const baseClass = "acl-validation-report";

interface IProps {
    loadId: number;
    onClose(event: DialogCloseEvent): void;
}

const ValidationStatus: React.FC<{ title: string; reportType: string; statuses: Errors; openAll: boolean }> = ({ title, reportType, statuses, openAll }) => {
    if (reportType === ReportType.commission) {
        return (
            <>
                <h3 className={`${baseClass}__status__title`}>{title}</h3>
                {Object.keys(statuses).map(status => (
                    <details className={`${baseClass}__details`} open={openAll} key={status}>
                        <summary className={`${baseClass}__summary`}>
                            <h3>{status !== "undefined" ? status : "Other"}</h3>
                        </summary>
                        <div className={`${baseClass}__detail-errors`}>
                            {Object.values(statuses[status]).map((err: IValidationStatus, i) => (
                                <div className={`${baseClass}__single-error`} key={i}>
                                    <strong>{err.message}</strong>
                                    <br />
                                    {err.origin}
                                    <br />
                                    Value: {err.value}
                                </div>
                            ))}
                        </div>
                    </details>
                ))}
            </>
        );
    } else {
        return !statuses.length ? null : (
            <dl className={`${baseClass}__status`}>
                <dt className={`${baseClass}__status__title`}>{title}</dt>
                {(statuses as IValidationStatus[]).map((status, index) => {
                    if (reportType === ReportType.commission) {
                        const { message, value, origin } = status;

                        return (
                            <dd key={`${index}-${value}`} className={`${baseClass}__status__section`}>
                                <p className={`${baseClass}__status__message`}>{message}</p>
                                <p className={`${baseClass}__status__message`}>{origin}</p>
                                <p className={`${baseClass}__status__message`}>{value}</p>
                            </dd>
                        );
                    } else {
                        const { fieldName, message, appliesTo } = status;
                        const items =
                            appliesTo &&
                            appliesTo.map((item, index) => (
                                <li key={`${index}-${item.isin}`} className={`${baseClass}__status__item`}>
                                    <strong className={`${baseClass}__status__isin`}>{item.isin}</strong>
                                    {item.value && item.value !== item.isin && <em className={`${baseClass}__status__value`}> {item.value}</em>}
                                </li>
                            ));

                        return (
                            <dd key={`${index}-${fieldName}`} className={`${baseClass}__status__section`}>
                                <h4 className={`${baseClass}__status__filename`}>{fieldName}</h4>
                                <p className={`${baseClass}__status__message`}>{message}</p>
                                {appliesTo && items && (
                                    <details className={`${baseClass}__status__details`} open={openAll}>
                                        <summary className={`${baseClass}__status__summary`}>
                                            <span className="k-icon" />
                                            Applicable ISINs
                                        </summary>
                                        <ol className={`${baseClass}__status__list`}>{items}</ol>
                                    </details>
                                )}
                            </dd>
                        );
                    }
                })}
            </dl>
        );
    }
};

const ValidationReport: React.FC<IProps> = ({ loadId, onClose = (): void => {} }) => {
    const componentEl = useRef(null);
    const [openAll, setOpenAll] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        loading: loadingValidationReport,
        error,
        data,
        refetch: refetchValidationReport,
        networkStatus,
    } = useQuery(GET_VALIDATION_REPORT, {
        variables: { loadId },
        notifyOnNetworkStatusChange: true,
        onError: error => {
            setErrorMessage("Error loading Validation Report.");
        },
        onCompleted: data => {
            setErrorMessage("");
        },
    });

    useEffect(() => {
        setLoading(loadingValidationReport || networkStatus === NetworkStatus.refetch || loadingPDF);
    }, [loadingValidationReport, networkStatus, loadingPDF]);

    const onCloseDialog = (event): void => onClose(event);

    const exportPDFWithMethod = (): void => {
        setOpenAll(true);
        setLoadingPDF(true);
        setTimeout(() => {
            savePDF(
                componentEl.current,
                {
                    paperSize: "a4",
                    margin: "15mm",
                    fileName: "validation-report",
                    scale: 0.5,
                },
                () => {
                    setLoadingPDF(false);
                    setOpenAll(false);
                },
            );
        });
    };

    // TODO: Remove this useEffect if it's unnecessary
    useEffect(() => {
        refetchValidationReport({ loadId });
    }, [loadId]);

    const showPDFExport = ({ loadInformation: { loadId, fileName, validFrom, createdDate, reportType }, errors, warnings }): React.ReactElement => (
        <PDFExport paperSize="a4" margin="15mm" fileName="validation-report" author="Acolin">
            <div ref={componentEl}>
                <ResponsiveTable>
                    <table className={`${baseClass}__header`}>
                        <thead className={`${baseClass}__fields`}>
                            <tr>
                                <th className={`${baseClass}__small`}>Load ID</th>
                                <th>File name</th>
                                <th className={`${baseClass}__small`}>Valid from</th>
                                <th className={`${baseClass}__small`}>Created date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={`${baseClass}__small`}>{loadId}</td>
                                <td>{fileName}</td>
                                <td className={`${baseClass}__small`}>{validFrom}</td>
                                <td className={`${baseClass}__small`}>{createdDate}</td>
                            </tr>
                        </tbody>
                    </table>
                </ResponsiveTable>
                {errors && <ValidationStatus title="Errors" reportType={reportType} statuses={errors} openAll={openAll} />}
                {warnings && <ValidationStatus title="Warnings" reportType={reportType} statuses={warnings} openAll={openAll} />}
            </div>
        </PDFExport>
    );

    return (
        <Dialog className={`${baseClass}`} title="Validation Report" onClose={onCloseDialog}>
            <div className={`${baseClass}__content`}>
                {data?.validationReport && showPDFExport(data.validationReport)}
                {errorMessage && (
                    <div className={`${baseClass}__error`}>
                        <p>{errorMessage}</p>
                        <Button
                            onClick={(): void => {
                                refetchValidationReport();
                            }}
                        >
                            Try Again
                        </Button>
                    </div>
                )}
                {loading && <LoadingPanel />}
            </div>
            <footer className={`${baseClass}__actions`}>
                <Button
                    disabled={isEmpty(data?.validationReport) || loadingPDF}
                    onClick={exportPDFWithMethod}
                    className={`${baseClass}__static__footer-button`}
                >
                    {loadingPDF ? (
                        <>
                            <Icon name="loading" spacing="right" /> Downloading
                        </>
                    ) : (
                        "Download"
                    )}
                </Button>
                <Button themeColor="primary" onClick={onCloseDialog}>
                    Close
                </Button>
            </footer>
        </Dialog>
    );
};

export default ValidationReport;
