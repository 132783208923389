import React, { useCallback } from "react";

import { Button } from "@progress/kendo-react-buttons";

import Icon from "@components/common/Icon";

import "./Reload.scss";

const baseClass = "acl-reload";

const Reload: React.FC<{}> = () => {
    const handleClick = useCallback(() => window.location.reload(), []);

    return (
        <div className={`${baseClass}`}>
            <Icon name="alarm-bell" spacing="right" className={`${baseClass}__icon`} />
            <p className={`${baseClass}__message`}>There's a new version of this app!</p>
            <Button themeColor="primary" onClick={handleClick} className={`${baseClass}__button`}>
                Reload
            </Button>
        </div>
    );
};

export default Reload;
