import React, { useState } from "react";
import { publish } from "pubsub-js";

import {
    Upload,
    UploadFileInfo,
    UploadOnAddEvent,
    UploadOnRemoveEvent,
    UploadOnBeforeUploadEvent,
    UploadOnProgressEvent,
    UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import { Error } from "@progress/kendo-react-labels";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";

import { PSChannel } from "@src/common/types";
import { logout } from "@src/common/token";

import dataMessages from "./en-data.json";
import documentMessages from "./en-document.json";
import commissionMessages from "./en-commission.json";
import "./Upload.scss";
import classNames from "classnames";

const baseClass = "acl-upload";

interface IProps {
    url: string;
    fieldName?: string;
    extensions?: string[];
    multiple?: boolean;
    customClass?: string;
    dataType?: string;
    tutorialClass?: string;
    onSuccess?(response?: { message: string }): void;
    onError?(response: { message: string }): void;
    onAddHandle?(fileType: string): void;
    onRemoveHandle?(): void;
    onProgressHandle?(): void;
}

interface IState {
    files: UploadFileInfo[];
    loading: boolean;
    error: string;
}

const initialState: IState = {
    files: [],
    loading: false,
    error: "",
};

export const getLocalization = (type: string): string => {
    switch (type) {
        case "commission":
            return "en-CA";
        case "documents":
            return "en-US";
        default:
            return "en-EN";
    }
};

loadMessages(dataMessages, "en-EN");
loadMessages(documentMessages, "en-US");
loadMessages(commissionMessages, "en-CA");

const FileUpload: React.FC<IProps> = (props: IProps) => {
    const {
        url,
        fieldName = "file",
        extensions = [".xlsx"],
        customClass = "xlsx",
        dataType = "data",
        multiple = true,
        onSuccess = (): void => {},
        onError = (): void => {},
        onAddHandle = (): void => {},
        onRemoveHandle = (): void => {},
        onProgressHandle = (): void => {},
        tutorialClass,
    } = props;
    const [state, setState] = useState<IState>(initialState);
    // const { setNotification } = useContext(AppContext);

    function onAdd(event: UploadOnAddEvent): void {
        // console.log("onAdd", event.affectedFiles);
        const files = event.newState.reduce((acc, current) => {
            const exists = acc.find(file => file.name === current.name);
            return exists ? acc : acc.concat([current]);
        }, []); // Filter duplicates
        files.forEach(file => {
            file.name = file.name.replace(/&amp;/g, "&");
        });

        setState(prevState => ({ ...prevState, files: multiple ? files : files.slice(0, 1) }));

        onAddHandle(event.affectedFiles[0]?.extension);
    }

    function onRemove(event: UploadOnRemoveEvent): void {
        // console.log("onRemove", event.affectedFiles);
        setState(prevState => ({ ...prevState, files: event.newState }));
        onRemoveHandle();
    }

    function onBeforeUpload(event: UploadOnBeforeUploadEvent): void {
        // console.log("onBeforeUpload", event.files);
        // const files = event.files.filter((file: UploadFileInfo) => file.size > 100000);
        const params = Object.fromEntries(new URL(url).searchParams);

        event.additionalData = params;

        setState(prevState => ({ ...prevState, files: event.files }));
    }

    function onProgress(event: UploadOnProgressEvent): void {
        // console.log("onProgress", event.affectedFiles);
        setState(prevState => ({ ...prevState, files: event.newState }));
        onProgressHandle();
    }

    function onStatusChange(event: UploadOnStatusChangeEvent): void {
        let newState = { ...state, files: event.newState };

        const request = event.response?.request;
        const status = request?.status;
        const response = request?.response;
        const responseURL = request?.responseURL;

        // Kendo upload component doesn't use axios for handling its requests
        // That is why we had to write logic for displaying error message,
        // and for logging out user in case the response returns status 401
        // inside this component in onStatusChange event
        if (request && status === 401) {
            localStorage.removeItem("authToken");
            logout();
            return;
        } else if (status === 400 || status > 401) {
            publish(PSChannel.Error, {
                config: {
                    url: new URL(responseURL)?.pathname?.replace("/api/", "/"),
                },
                data: {
                    error: response.error,
                },
            });
            newState = { ...newState, error: response?.message };
            onError(response);
        }

        if (request && status === 200) {
            onSuccess(event.response.response);
        }

        setState(prevState => ({ ...prevState, ...newState }));
    }

    return (
        <div className={`${baseClass}`}>
            <LocalizationProvider language={getLocalization(dataType)}>
                <IntlProvider locale="en">
                    <Upload
                        className={classNames(`${baseClass}__widget ${baseClass + "__" + customClass}`, tutorialClass)}
                        restrictions={{
                            allowedExtensions: extensions,
                            minFileSize: 0,
                            maxFileSize: 20971520,
                        }}
                        saveField={fieldName}
                        saveHeaders={{ "Content-Type": "multipart/form-data", authorization: `Bearer ${localStorage.getItem("authToken")}` }}
                        files={state.files}
                        multiple={multiple}
                        autoUpload={false}
                        withCredentials={true}
                        showActionButtons={true}
                        saveUrl={url}
                        disabled={false}
                        data-type={dataType}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onBeforeUpload={onBeforeUpload}
                        onProgress={onProgress}
                        onStatusChange={onStatusChange}
                    />
                </IntlProvider>
            </LocalizationProvider>
            {state.error && <Error className={`${baseClass}__error`}>{state.error}</Error>}
        </div>
    );
};

export default FileUpload;
