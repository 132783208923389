import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { GOOGLE_ANALYTICS_ID, isDebug } from "@src/common/config";

type useStateSafeParams<T> = T | (() => T);
type dispatch<T> = React.Dispatch<React.SetStateAction<T>>;

export const usePageTracking = (): typeof ReactGA => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(!!ReactGA.ga());

    useEffect(() => {
        if (GOOGLE_ANALYTICS_ID && !initialized) {
            ReactGA.initialize(GOOGLE_ANALYTICS_ID, { debug: isDebug });
            ReactGA.set({ anonymizeIp: true });
            setInitialized(!!ReactGA.ga());
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);

    return ReactGA;
};

/**
 * Wrapper around React's `useState` hook.
 * Use this hook to prevent memory leak as this wont call set state on unmounted component.
 *
 * @param initialValue initial state value
 */
export const useStateEx = <T>(initialValue: useStateSafeParams<T>): [T, dispatch<T>] => {
    const [val, setVal] = useState<T>(initialValue);
    const mountedRef = useRef<boolean>();

    useEffect(() => {
        mountedRef.current = true;
        return (): void => {
            mountedRef.current = false;
        };
    }, []);

    const setValue: dispatch<T> = useCallback(
        (s: React.SetStateAction<T>) => {
            if (mountedRef.current) {
                setVal(s);
            }
        },
        [setVal],
    );

    return [val, setValue];
};

type Dimensions = {
    width: number;
    height: number;
};

function getWindowDimensions(): Dimensions {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const useWindowDimensions = (): Dimensions => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize(): void {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return (): void => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};
