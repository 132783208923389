// prettier-ignore
const docTypes = [
    "??",
    "AID",
    "AR",
    "FP",
    "INF",
    "KIID",
    "LEG",
    "MC",
    "MR",
    "PR",
    "PRP",
    "QR",
    "REG",
    "SAR",
    "SP",
    "STAT",
    "SUB",
] as const;

export type DocType = (typeof docTypes)[number];

export type Document = {
    uid: string;
    code: string;
    date: Date;
    error: number;
    message: string;
};

export type NodeISIN = {
    isin: string;
    id?: number;
    parentId?: number;
    isExpandable?: boolean;
    // Documents by types
    documents: Record<DocType, Document[]>;
};

export type OverviewReport = Array<{
    country: string;
    id?: number;
    parentId?: number;
    // Funds
    items: Array<{
        name: string;
        id?: number;
        parentId?: number;
        isFund?: boolean;
        isExpandable?: boolean;
        // Subfunds
        items: Array<{
            name: string;
            id?: number;
            parentId?: number;
            isSubfund?: boolean;
            isExpandable?: boolean;
            // ISINs and documents
            items: Array<{
                id?: number;
                parentId?: number;
                isExpandable?: boolean;
                // ISINs with documents
                items: NodeISIN[];
            }>;
        }>;
    }>;
}>;

export interface ILoad {
    id: number;
    fileName: string;
    validFrom: string;
    loadDate: string;
    lastUpdate: string;
    status: string;
    hasValidationReport?: boolean;
}

interface ILoadInformation {
    loadId: number;
    fileName: string;
    validFrom: string;
    status: string;
    createdBy: string;
    createdDate: string;
    reportType: string;
}

interface IAppliesTo {
    isin: string;
    value: string;
}

export interface IValidationStatus {
    fieldName: string;
    message: string;
    appliesTo: IAppliesTo[];
    value: string;
    origin: string;
    category: string;
}

export type Errors = IValidationStatus[] | Record<string, IValidationStatus[]>;

export interface IValidationReport {
    loadInformation?: ILoadInformation;
    errors?: Errors;
    warnings?: Errors;
}

export enum ReportType {
    commission = "commission",
    other = "other",
}
