import React from "react";
import classNames from "classnames";

import { useUIContext } from "@src/common/Context";

import "./Hamburger.scss";

const baseClass = "acl-hamburger";

interface IProps {
    onPress?(status: boolean): void;
    className?: string;
}

const Hamburger: React.FC<IProps> = ({ onPress = (): void => {}, className }) => {
    const [context, setContext] = useUIContext();
    const classes: string = classNames(baseClass, className);

    const toggleMenu = (): void => {
        const isSidebarOpen = !context.isSidebarOpen;
        setContext({ ...context, isSidebarOpen });
        onPress(isSidebarOpen);
    };

    return (
        <button id="hamburger" className={classes} tabIndex={-1} aria-hidden="true" aria-pressed={context.isSidebarOpen} onClick={toggleMenu}>
            Menu
            <i className={`${baseClass}__line`} />
        </button>
    );
};

export default Hamburger;
