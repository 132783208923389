import React, { useCallback, useState, useMemo } from "react";
import * as dateFns from "date-fns";
import { useQuery } from "@apollo/client";

import { CompositeFilterDescriptor, FilterDescriptor, process, State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridCellProps, GridExpandChangeEvent } from "@progress/kendo-react-grid";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Chip, Button } from "@progress/kendo-react-buttons";

import { formatAmount } from "@src/common/util";
import Page, { Title } from "@components/containers/Page";
import { Card, CardHeader, CardContent, BackToTop, DropdownFilterCell, RangeFilterCell, DateRangeFilterCell, Icon, LoadingPanel } from "@components/common";
import ResponsiveTable from "@components/common/ResponsiveTable";

import "./Invoices.scss";
import { GET_ALL_INVOICES } from "@src/common/graphql";

const baseClass = "acl-page-invoices";
interface IProps {
    symbol?: string;
    currency?: string;
    amount: number;
}

const statuses = ["Paid", "Open"];

const mapStatusClassToColor = (status: string): "base" | "info" | "success" | "warning" | "error" => {
    switch (status) {
        case "Open":
            return "warning";
        case "Paid":
            return "success";
        default:
            return "error";
    }
};

const StatusCell: React.FC<GridCellProps> = ({ dataItem }) => (
    <td>
        <Chip
            text={dataItem.status}
            value="chip"
            fillMode={"solid"}
            themeColor={mapStatusClassToColor(dataItem.status)}
            className={`${baseClass}__status ${baseClass}__status--${dataItem.statusClass}`}
        />
    </td>
);

const DateCell: React.FC<GridCellProps> = ({ dataItem, field }) => <td>{dateFns.format(dataItem[field], "yyyy-MM-dd")}</td>;

const StatusFilterCell: React.FC<{}> = props => <DropdownFilterCell {...props} data={statuses} defaultItem={"All"} />;

const AmountCell: React.FC<GridCellProps> = ({ dataItem, field }) => <td>{formatAmount(dataItem[field])}</td>;

const DetailComponent: React.FC<GridCellProps> = ({ dataItem }) => (
    <div>
        <section>
            <div className={`${baseClass}__details`}>
                <Grid data={dataItem.salesInvoiceLines} scrollable="none" className={`${baseClass}__details-grid`}>
                    <GridColumn field="description" title="Description" />
                    <GridColumn field="amountIncludingTax" title="Amount" cell={AmountCell} />
                </Grid>
            </div>
        </section>
    </div>
);

const BalanceByCurrency: React.FC<IProps> = (props: IProps) => (
    <div className={`${baseClass}__balance-currency`}>
        <span className={`${baseClass}__currency`}>{props.currency}</span>
        <span className={`${baseClass}__amount`}>{formatAmount(props.amount)}</span>
    </div>
);

const initialDates = {
    invoiceDate: {
        min: null,
        max: null,
    },
    dueDate: {
        min: null,
        max: null,
    },
};

const Invoices: React.FC<{}> = () => {
    const [invoices, setInvoices] = useState([]);
    const [openBalance, setOpenBalance] = useState({});
    const [currencies, setCurrencies] = useState<string[]>([]);
    const [dataState, setDataState] = useState<State>({
        skip: 0,
        take: 20,
        sort: [
            {
                field: "invoiceNumber",
            },
        ],
        filter: {
            logic: "and",
            filters: [],
        },
    });
    const [dates, setDates] = useState(initialDates);

    const { loading, error, data } = useQuery(GET_ALL_INVOICES, {
        variables: { companyId: 106411, includeOpenBalance: true },
        fetchPolicy: "cache-first",
        onCompleted: data => {
            const mappedInvoices = data.invoices.invoices.map(invoice => ({
                ...invoice,
                dueDate: new Date(invoice?.dueDate),
                invoiceDate: new Date(invoice?.invoiceDate),
            }));

            setCurrencies(data.invoices.invoices.map(item => item.currencyCode).filter((value, index, self) => self.indexOf(value) === index));

            setInvoices(mappedInvoices);
            setOpenBalance(data.invoices.openBalance);
        },
    });

    const CurrenciesFilterCell: React.FC<{}> = props => <DropdownFilterCell {...props} data={currencies} defaultItem={"All"} />;

    const filterDate = (filterItem, field): void => {
        const { operator, value } = filterItem;

        setDates(prevState => {
            const prevMin = prevState[field].min;
            const prevMax = prevState[field].max;
            return { ...prevState, [field]: { min: operator === "gte" ? value : prevMin, max: operator === "lte" ? value : prevMax } };
        });
    };

    const setFilter = (newFilterItems: Array<FilterDescriptor | CompositeFilterDescriptor>): void => {
        setDataState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                filters: newFilterItems,
            },
        }));
    };

    const handleDateFilterChange = useCallback(
        (filterItem, field) => {
            const { operator, value } = filterItem;
            const filters: FilterDescriptor[] = dataState.filter.filters.map(filter => filter as FilterDescriptor);
            const oldFilterItem = filters.find(item => item.field === field && item.operator === operator);
            const newFilterItem: FilterDescriptor = { field, operator, value };
            filterDate(filterItem, field);

            if (oldFilterItem) {
                const otherFilterItems = filters.filter(item => item !== oldFilterItem);
                setFilter([...otherFilterItems, newFilterItem]);
            } else {
                setFilter([...dataState.filter.filters, newFilterItem]);
            }
        },
        [dataState, setFilter],
    );

    const MyDateFilterCell: React.FC<GridCellProps> = ({ field }) => (
        <DateRangeFilterCell min={dates[field].min} max={dates[field].max} onDateFilterChange={(e): void => handleDateFilterChange(e, field)} />
    );

    const MyRangeFilterCell: React.FC = props => <RangeFilterCell {...props} />;

    const columns = useMemo(
        () => [
            { field: "number", title: "Invoice Number" },
            { field: "customerName", title: "Charged to" },
            { field: "category", title: "Category" },
            { field: "currencyCode", title: "Currency", width: 95, filterCell: CurrenciesFilterCell },
            { field: "totalAmountIncludingTax", title: "Invoice Amount", cell: AmountCell, width: 120, filterCell: MyRangeFilterCell },
            { field: "invoiceDate", title: "Invoice Date", width: 130, cell: DateCell, filterCell: MyDateFilterCell },
            { field: "dueDate", title: "Due Date", width: 130, cell: DateCell, filterCell: MyDateFilterCell },
            { field: "remainingAmount", title: "Open Balance", cell: AmountCell, width: 120, filterCell: MyRangeFilterCell },
            { field: "status", title: "Status", cell: StatusCell, width: 115, filterCell: StatusFilterCell },
        ],
        [dates, currencies],
    );

    const exportColumns = [
        { field: "number", title: "Invoice Number" },
        { field: "customerName", title: "Charged to" },
        { field: "category", title: "Category" },
        { field: "currencyCode", title: "Currency" },
        { field: "totalAmountIncludingTax", title: "Invoice Amount", cellOptions: { format: "#,##0.00" } },
        { field: "invoiceDate", title: "Invoice Date", width: 130, cellOptions: { format: "YYYY-MM-DD" } },
        { field: "dueDate", title: "Due Date", width: 130, cellOptions: { format: "YYYY-MM-DD" } },
        { field: "remainingAmount", title: "Open Balance", cellOptions: { format: "#,##0.00" } },
        { field: "status", title: "Status" },
    ];

    const dataStateChange = (event): void => setDataState(event.dataState);

    const expandChange = (event: GridExpandChangeEvent): void => {
        const newData = invoices.map(item => {
            if (item.number === event.dataItem.number) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setInvoices(newData);
    };

    const resetFilter = (): void => {
        setFilter([]);
        setDates(initialDates);
    };

    let _export;

    const exportExcel = (): void => {
        _export.save();
    };

    return (
        <Page className={baseClass}>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>Invoices</Title>
            </header>
            <div className={`${baseClass}__static`}>
                {loading ? (
                    <LoadingPanel />
                ) : (
                    <>
                        {Object.keys(openBalance).length > 0 && (
                            <Card className={`${baseClass}__content`}>
                                <CardHeader>
                                    <h3 className={`${baseClass}__title`}>Open Balance</h3>
                                </CardHeader>
                                <CardContent>
                                    <div className={`${baseClass}__balances`}>
                                        {Object.keys(openBalance).map(key => (
                                            <BalanceByCurrency key={key} currency={key} amount={openBalance[key]} />
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        )}
                        {invoices && (
                            <Card className={`${baseClass}__content`}>
                                <CardHeader className={`${baseClass}__header`}>
                                    <h3 className={`${baseClass}__title`}>Invoices</h3>
                                    <div className={`${baseClass}__action-items`}>
                                        <Button onClick={resetFilter}>
                                            Reset Filter
                                            <span className="k-icon k-i-filter-clear" />
                                        </Button>
                                    </div>
                                </CardHeader>
                                <CardContent>
                                    <ExcelExport
                                        data={invoices}
                                        fileName="Acolin Client Portal - Extract of Invoices"
                                        ref={(exporter): void => {
                                            _export = exporter;
                                        }}
                                    >
                                        {exportColumns.map(({ field, ...other }) => (
                                            <ExcelExportColumn key={field} field={field} {...other} />
                                        ))}
                                    </ExcelExport>
                                    <ResponsiveTable>
                                        <Grid
                                            sortable={true}
                                            filterable={true}
                                            reorderable={false}
                                            scrollable="none"
                                            pageable={{
                                                buttonCount: 4,
                                                pageSizes: true,
                                            }}
                                            data={process(invoices, dataState)}
                                            {...dataState}
                                            onDataStateChange={dataStateChange}
                                            detail={DetailComponent}
                                            expandField="expanded"
                                            onExpandChange={expandChange}
                                        >
                                            {columns.map(({ field, ...other }) => (
                                                <GridColumn key={field} field={field} {...other} />
                                            ))}
                                        </Grid>
                                    </ResponsiveTable>
                                    <div className={`${baseClass}__actions`}>
                                        <Button onClick={exportExcel}>Export .xlsx</Button>
                                    </div>
                                </CardContent>
                            </Card>
                        )}
                    </>
                )}
            </div>
            <BackToTop />
        </Page>
    );
};

export default Invoices;
