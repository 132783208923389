import React, { ReactNode } from "react";

import Layout from "@components/containers/Layout";
import { Logo, MobileDisclaimer } from "@components/common";

import "./AuthLayout.scss";

const baseClass = "acl-layout--auth";

interface Props {
    children?: ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => (
    <Layout className={`${baseClass}`}>
        <header className={`${baseClass}__header`}>
            <Logo isAuth />
        </header>
        {children}
        <MobileDisclaimer />
    </Layout>
);

export default AuthLayout;
