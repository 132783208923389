import { shouldDisableWorkers } from "./config";
import Worker from "@workers/session";
import { WWMessage } from "@src/common/types";
import { isDebug } from "@src/common/config";

type Token = { expiry?: Date };

const worker = !shouldDisableWorkers ? new Worker() : null; // Ignore workers when running unit tests
const token: Token = {};

/**
 * Checks if token expiry is valid datetime object and if it's set in the future
 *
 * @param {Date} expiry Token expiration datetime
 * @returns {boolean}
 */
const isValid = (expiry: Date): boolean => expiry instanceof Date && !isNaN(expiry.getTime()) && expiry.getTime() > Date.now();

/**
 * Converts token expiry object into datetime
 *
 * @param {Date} expiry Token expiration datetime
 * @returns {Date}
 */
const toDate = (value: string): Date => {
    const expiry = new Date(value);
    return isValid(expiry) ? expiry : null;
};

/**
 * Updates the 'static' token expiry object
 *
 * @param {Date} expiry Token expiration datetime
 */
const updateWorker = (expiry: Date): void => worker.postMessage({ message: WWMessage.Update, payload: { expiry } });

/**
 * Returns the session expiry date stored in local storage
 * Updates the web worker responsible for checking if the session is about to expire
 *
 * @returns {Date} Session expiry date
 */
export const getTokenExpiry = (): Date => {
    const expiry = localStorage.getItem("tokenExpiry");

    try {
        token.expiry = toDate(isDebug ? expiry : atob(expiry));
    } catch (error) {
        token.expiry = toDate(expiry);
    }

    updateWorker(token.expiry);

    return token.expiry;
};

/**
 * Starts web worker
 *
 */
const startWorker = (): void => worker.postMessage({ message: WWMessage.Start, payload: { expiry: getTokenExpiry() } });

/**
 * Stops web worker job
 *
 */
const stopWorker = (): void => worker.postMessage({ message: WWMessage.Stop });

/**
 * Query web worker parameters
 *
 */
const queryWorker = (): void => worker.postMessage({ message: WWMessage.Query });

/**
 * Stores the session expiry date in local storage
 * Updates the web worker responsible for checking if the session is about to expire
 *
 * @param {string} Stringified date in UTC format
 */
export const setTokenExpiry = (expiry: string): void => {
    token.expiry = toDate(expiry);

    localStorage.setItem("tokenExpiry", isValid(token.expiry) ? (isDebug ? token.expiry.toISOString() : btoa(token.expiry.toISOString())) : "");

    updateWorker(token.expiry);
};

/**
 * Clear token expiry and redirect to login page
 *
 */
export const logout = (isTokenExpired?: boolean): void => {
    if (isTokenExpired) {
        window.location.assign("/logout?tokenExpired=true");
    } else {
        window.location.assign("/logout");
    }
};

/**
 * Run web worker to query token expiration
 *
 * @param {Function} Callback function to invoke if token is about to expire
 */
export const queryTokenExpiry = async (onExpiry: (payload: { minutes: number; formatted: string }) => void = (): void => {}): Promise<void> => {
    startWorker();

    worker.onmessage = ({ data: { message, payload } }): void => {
        isDebug && console.log("FROM WW:", payload);
        if (message !== WWMessage.Result || !payload || typeof payload?.minutes !== "number") {
            return;
        }

        payload.minutes <= 0 ? logout(true) : onExpiry(payload);
    };
};
