import React, { useEffect, useRef, useState } from "react";
import { publish } from "pubsub-js";
import { NetworkStatus, useLazyQuery } from "@apollo/client";
import classNames from "classnames";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";

import { useAppUserContext } from "@src/common/Context";
import { generateMonths, generateQuarters, getFilenameFromContentDisposition } from "@src/common/util";
import axios from "@src/common/http";
import { API_ENDPOINT } from "@src/common/config";
import { ERole, PSChannel } from "@src/common/types";
import Page, { Title } from "@components/containers/Page";
import { Card, CardHeader, CardContent, CardFooter, BackToTop, Upload as FileUpload, Icon, LoadingPanel } from "@components/common";

import DataLoads from "../_components/DataLoads";
import { IState, initialState } from "./types";
import "./CommissionData.scss";
import { GET_COMMISSION_LOADS, GET_COMMISSION_SETTINGS } from "@src/common/graphql";
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from "react-joyride";
import { useSetState } from "react-use";
import { errors } from "@src/common/errors";

const baseClass = "acl-page-commission-data";

interface TutorialState {
    run: boolean;
    steps: Step[];
}

const CommissionData: React.FC<{}> = () => {
    const [state, setState] = useState<IState>(initialState);
    const [appUser, _setAppUser] = useAppUserContext();
    const [loadingLoads, setLoadingLoads] = useState<boolean>(true);
    const [hasFundGroups, setHasFundGroups] = useState<boolean>(true);
    const [isMonthlyFrequency, setIsMonthlyFrequency] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [{ run, steps }, setTutorialState] = useSetState<TutorialState>({
        run: false,
        steps: [
            {
                title: "Upload ACT template",
                content: "Let us guide you through delivering your new ACT file",
                locale: { skip: <strong aria-label="skip">Close</strong> },
                placement: "center",
                target: "body",
            },
            {
                content: "Click here to select quarter",
                placement: "bottom",
                styles: {
                    options: {
                        width: 300,
                    },
                },
                target: ".quarter_selector",
                title: "Select quarter",
            },
            {
                content: "Click here to select the Fund group you want to update ACT file for",
                placement: "bottom",
                styles: {
                    options: {
                        width: 300,
                    },
                },
                target: ".fund_group_selector",
                title: "Select fund group",
            },
            {
                content: "Click here to download the latest ACT for that criteria",
                placement: "bottom",
                styles: {
                    options: {
                        width: 300,
                    },
                },
                target: ".download_button_selector",
                title: "Download template",
            },
            {
                content: "Once you edit and save your file, upload it back to Acolin here",
                placement: "top",
                target: ".upload_selector",
                title: "Upload files",
            },
        ],
    });

    const helpers = useRef<StoreHelpers>();

    const setHelpers = (storeHelpers: StoreHelpers): void => {
        helpers.current = storeHelpers;
    };

    const [getCommissionLoads, { loading: loadingGetLoads, error: errorLoads, data: dataLoads, refetch: refetchLoads, networkStatus: networkStatusLoads }] =
        useLazyQuery(GET_COMMISSION_LOADS, {
            variables: { companyId: appUser.companyId },
            notifyOnNetworkStatusChange: true,
        });

    useEffect(() => {
        setLoadingLoads(networkStatusLoads === NetworkStatus.refetch || loadingGetLoads);
    }, [loadingGetLoads, networkStatusLoads]);

    const onAddHandle = (file): void => {
        setState(prevState => ({
            ...prevState,
            uploadUrl: state.xlsxUploadUrl,
            shouldShowFundGroups: file === ".xlsx",
        }));
    };

    // TODO create button to trigger start tour
    const handleClickStartTour = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();

        setTutorialState({
            run: true,
        });
    };

    const handleJoyrideCallback = (data: CallBackProps): void => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status) || data.action === "close") {
            setTutorialState({ run: false });
        }
        if (data.action === "close") {
            helpers.current.skip();
        }
    };

    const onRemoveHandle = (): void => setState(prevState => ({ ...prevState, shouldShowFundGroups: false }));

    const onProgressHandle = (): void => onRemoveHandle();

    const [getCommissionSettings, { loading: loadingCommissionSettings }] = useLazyQuery(GET_COMMISSION_SETTINGS, {
        variables: { companyId: appUser.companyId },
        fetchPolicy: "cache-first",
        onCompleted: ({ commissionSettings }) => {
            if (!commissionSettings?.actFundGroups.length) {
                setHasFundGroups(false);
            }
            const fundGroups = commissionSettings?.actFundGroups ?? [];
            const firstFundGroup = fundGroups[0] ?? null;
            setIsMonthlyFrequency(commissionSettings?.actFrequency === "m" ? true : false);
            setState(prevState => ({
                ...prevState,
                fundGroups,
                selectedFundGroupUpload: firstFundGroup,
                selectedFundGroupDownload: firstFundGroup,
            }));
        },
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }
        getCommissionLoads().catch(error => console.error(error));
        getCommissionSettings().catch(error => console.error(error));
    }, []);

    const handleDownload = (): void => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }

        setDownloading(true);
        const selectedMonth = isMonthlyFrequency ? state.selectedMonth.month : "";
        const selectedQuarter = !isMonthlyFrequency ? state.selectedQuarter.quarter : "";
        const selectedYear = isMonthlyFrequency ? state.selectedMonth.year : state.selectedQuarter.year;
        const params = { companyId: appUser.companyId, fundGroupId: state.selectedFundGroupDownload.id, type: "commission", year: selectedYear };
        if (selectedMonth) {
            params["month"] = selectedMonth;
        } else {
            params["quarter"] = selectedQuarter;
        }
        axios
            // .get(`${API_ENDPOINT}/portal/fund-data`, { responseType: "blob", params })
            .get(`${API_ENDPOINT}/fund-data`, { responseType: "blob", params })
            .then(response => {
                if (response.status !== 200) {
                    setDownloading(false);
                    return;
                }
                // Get original file name from header
                const header = response.headers["content-disposition"];
                const filename = getFilenameFromContentDisposition(header, "act-template.xlsx");

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                setDownloading(false);
            })
            .catch(error => {
                setDownloading(false);
                console.error(error);
            });
    };

    useEffect(() => {
        generateQuarters().then(quarters => {
            setState(prevState => ({
                ...prevState,
                quarters,
                selectedQuarter: quarters[0],
            }));
        });
        generateMonths().then(months => {
            setState(prevState => ({
                ...prevState,
                months,
                selectedMonth: months[0],
            }));
        });
    }, []);

    useEffect(() => {
        if (state.selectedFundGroupUpload) {
            // const url = `${API_ENDPOINT}/portal/fund-data?companyId=${appUser.companyId}&type=commission&fundGroupId=${state.selectedFundGroupUpload.id}`;
            const url = `${API_ENDPOINT}/fund-data?companyId=${appUser.companyId}&type=commission&fundGroupId=${state.selectedFundGroupUpload.id}`;
            setState(prevState => ({
                ...prevState,
                xlsxUploadUrl: url,
                uploadUrl: url,
            }));
        }
    }, [state.selectedFundGroupUpload]);

    const uploadClasses = classNames(`${baseClass}__upload-wrapper`, state.shouldShowFundGroups && `${baseClass}--fund-groups-upload`);

    return (
        <Page className={`${baseClass}`}>
            <Joyride
                callback={handleJoyrideCallback}
                continuous
                hideCloseButton
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                getHelpers={setHelpers}
                styles={{
                    options: {
                        zIndex: 10000,
                        primaryColor: "#7d8f80",
                    },
                }}
            />
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>
                    Commission Data
                    <a data-tooltip-id="tooltip" data-tooltip-content={"Acolin Commission Template (ACT)​"} data-tooltip-place="top">
                        <Icon className={`${baseClass}__tooltip`} name="question-circle" />
                    </a>
                </Title>
            </header>

            {loadingCommissionSettings ? (
                <Card className={`${baseClass}__static__placeholder`}>
                    <CardContent className={`${baseClass}__static__content`}>
                        <LoadingPanel />
                    </CardContent>
                </Card>
            ) : hasFundGroups ? (
                <>
                    {appUser.roles.includes(ERole.CommissionManager) && (
                        <div className={`${baseClass}__static`}>
                            <Card className={`${baseClass}__static__upload`}>
                                <CardHeader className={`${baseClass}__static__header`}>
                                    <h3 className={`${baseClass}__static__title`}>Upload</h3>
                                </CardHeader>
                                <CardContent className={`${baseClass}__static__content`}>
                                    <div className={uploadClasses}>
                                        <FileUpload
                                            url={state.uploadUrl}
                                            extensions={[".xlsx"]}
                                            multiple={false}
                                            dataType="commission"
                                            onSuccess={(): void => {
                                                if (!appUser.companyId) {
                                                    publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
                                                    return;
                                                }
                                                refetchLoads();
                                            }}
                                            onRemoveHandle={onRemoveHandle}
                                            onAddHandle={onAddHandle}
                                            onProgressHandle={onProgressHandle}
                                            tutorialClass={"upload_selector"}
                                        />
                                        {state.shouldShowFundGroups && (
                                            <div className={`${baseClass}__select-wrapper`}>
                                                <label className="k-label">Select fund group</label>
                                                <DropDownList
                                                    data={state.fundGroups}
                                                    textField="name"
                                                    dataItemKey="id"
                                                    value={state.selectedFundGroupUpload}
                                                    onChange={(e): void => setState(prevState => ({ ...prevState, selectedFundGroupUpload: e.target.value }))}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardContent>
                            </Card>
                            <Card className={`${baseClass}__static__download`}>
                                <CardHeader className={`${baseClass}__static__header`}>
                                    <h3 className={`${baseClass}__static__title`}>Acolin Commission Template (ACT)</h3>
                                </CardHeader>
                                <CardContent className={`${baseClass}__static__content`}>
                                    <p>Here you will find a selection of valid and checked ACTs for download.</p>
                                    <div className={`${baseClass}__datepicker`}>
                                        {isMonthlyFrequency ? (
                                            <DropDownList
                                                data={state.months}
                                                textField="text"
                                                dataItemKey="text"
                                                value={state.selectedMonth}
                                                onChange={(e): void =>
                                                    setState(prevState => ({
                                                        ...prevState,
                                                        selectedMonth: e.target.value,
                                                    }))
                                                }
                                            />
                                        ) : (
                                            <DropDownList
                                                data={state.quarters}
                                                textField="text"
                                                dataItemKey="text"
                                                value={state.selectedQuarter}
                                                className={"quarter_selector"}
                                                onChange={(e): void =>
                                                    setState(prevState => ({
                                                        ...prevState,
                                                        selectedQuarter: e.target.value,
                                                    }))
                                                }
                                            />
                                        )}

                                        <a
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={`Select for which ${isMonthlyFrequency ? "month" : "quarter"} you want to download your data.`}
                                            data-tooltip-place="top"
                                        >
                                            <Icon className={`${baseClass}__tooltip`} name="question-circle" />
                                        </a>
                                    </div>
                                    <div className={classNames(`${baseClass}__select-wrapper`, "fund_group_selector")}>
                                        <label className="k-label">Select fund group</label>
                                        <DropDownList
                                            data={state.fundGroups}
                                            textField="name"
                                            dataItemKey="id"
                                            value={state.selectedFundGroupDownload}
                                            onChange={(e): void => setState(prevState => ({ ...prevState, selectedFundGroupDownload: e.target.value }))}
                                        />
                                    </div>
                                </CardContent>
                                <CardFooter className={`${baseClass}__static__footer`}>
                                    {state.selectedQuarter && state.selectedFundGroupDownload && (
                                        <Button
                                            themeColor="primary"
                                            onClick={handleDownload}
                                            disabled={downloading}
                                            className={classNames(`${baseClass}__static__footer-button`, "download_button_selector")}
                                        >
                                            {downloading ? (
                                                <>
                                                    <Icon name="loading" spacing="right" /> Downloading
                                                </>
                                            ) : (
                                                "Download"
                                            )}
                                        </Button>
                                    )}
                                </CardFooter>
                            </Card>
                        </div>
                    )}
                    <div className={`${baseClass}__status`}>
                        <Card className={`${baseClass}__status__upload`}>
                            <CardHeader className={`${baseClass}__status__title`}>
                                <h3 className={`${baseClass}__card__title`}>Commission Data Loads</h3>
                                <Button
                                    onClick={(): void => {
                                        if (!appUser.companyId) {
                                            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
                                            return;
                                        }
                                        refetchLoads();
                                    }}
                                >
                                    Refresh
                                </Button>
                            </CardHeader>
                            <CardContent className={classNames(`${baseClass}__status__content`, `${baseClass}__status__content-loads`)}>
                                <DataLoads dataset={dataLoads?.loads} loading={loadingLoads} isCommissionData={true} />
                            </CardContent>
                        </Card>
                    </div>
                </>
            ) : (
                <Card className={`${baseClass}__static__placeholder`}>
                    <CardContent>
                        <p className={`${baseClass}__empty`}>You have not signed up to Acolin’s Distribution Network Management service.</p>
                        <p>
                            To find out more, please visit our{" "}
                            <a href="https://acolin.com" target="_blank">
                                website
                            </a>{" "}
                            or contact your Acolin representative {appUser.pointsOfContact.crm?.fullName} at{" "}
                            <a href={`mailto:${appUser.pointsOfContact.crm?.email || "crmteam@acolin.com"}`}>
                                {appUser.pointsOfContact.crm?.email || "crmteam@acolin.com"}
                            </a>
                            .
                        </p>
                    </CardContent>
                </Card>
            )}
            <BackToTop />
        </Page>
    );
};

export default CommissionData;
