import React, { useEffect, useState } from "react";
import { API_ENDPOINT } from "@src/common/config";
import { getInitials } from "@src/common/util";
import "./ContactImage.scss";
import axios from "@src/common/http";

type Props = {
    fullName: string;
    email: string;
    width?: string;
};

const baseClass = "acl-component-contact-image";

const ServiceOwner: React.FC<Props> = ({ fullName, email, width = "8rem" }) => {
    const [source, setSource] = useState(null);

    useEffect(() => {
        axios
            // .get(`${API_ENDPOINT}/portal/employee-photo?email=${email}`, { responseType: "arraybuffer" })
            .get(`${API_ENDPOINT}/employee-photo?email=${email}`, { responseType: "arraybuffer" })
            .then(image => {
                const blob = new Blob([image.data], { type: "image/png" });
                const reader = new FileReader();
                reader.onloadend = (): void => {
                    setSource(reader.result);
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => console.error(error));
    }, [email]);

    return (
        source && (
            <picture
                className={`${baseClass}__photo`}
                data-initials={getInitials(fullName)}
                style={{
                    width: width,
                    height: width,
                }}
            >
                <img
                    title={fullName}
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                    style={{
                        backgroundImage: `url(${source})`,
                    }}
                />
            </picture>
        )
    );
};

export default ServiceOwner;
