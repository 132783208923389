import React from "react";

import { getInitials } from "@src/common/util";
import Link from "@components/common/Link";

import "./UserInfo.scss";

const baseClass = "acl-user-info";

const UserInfo: React.FC<{ name: string; company: string; avatar?: string }> = ({ avatar, name, company }) => (
    <nav className={`${baseClass}`}>
        <Link className={`${baseClass}__profile`} type="text" to="/profile">
            <div className={`${baseClass}__avatar`}>{getInitials(name)}</div>
            <h4 data-testid="name" className={`${baseClass}__name`}>
                {name}
            </h4>
            <p data-testid="company" className={`${baseClass}__company`}>
                {company}
            </p>
        </Link>
        <Link className={`${baseClass}__action`} shape="round" icon="alarm-bell" to="/notifications">
            Notifications
        </Link>
        <Link className={`${baseClass}__action`} shape="round" icon="logout-1" to="/logout">
            Logout
        </Link>
    </nav>
);

export default React.memo(UserInfo);
