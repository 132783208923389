import React, { useEffect, useState, ReactNode } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { Tooltip } from "react-tooltip";

import { Card, CardContent, CardHeader, Icon } from "@components/common";

const baseClass = "acl-share-class-details-distribution";

type MapLegendItemProps = {
    color: string;
};

type Country = {
    NAME: string;
    NAME_LONG: string;
    ABBREV: string;
    FORMAL_EN: string;
    ISO_A2: string;
    ISO_A3: string;
    CONTINENT: string;
    REGION_UN: string;
    SUBREGION: string;
    legal: string;
    marketing: string;
    treeViewExpanded: boolean;
};

type Position = {
    coordinates: [number, number];
    zoom: number;
};

const rotationVectorEurope: [number, number, number] = [-20.0, -52.0, 0];
const rotationVectorAsia: [number, number, number] = [-90.0, -25.0, 10];
const rotationVectorSouthAmerica: [number, number, number] = [60.0, 22.0, 0];

/* eslint-disable camelcase */
const projections = {
    europe: {
        rotate: rotationVectorEurope,
        scale: 900,
    },
    asia: {
        rotate: rotationVectorAsia,
        scale: 600,
    },
    south_america: {
        rotate: rotationVectorSouthAmerica,
        scale: 450,
    },
};
/* eslint-enable camelcase */

export function getFillColorForMarketing(status: string): string {
    const normalizedStatus = status?.toLowerCase();
    switch (normalizedStatus) {
        case "yes":
            return "#7d8f80";
        case "res":
            return "#d89f5a";
        case "no":
            return "#B0B0B0";
        default:
            return "#E8E8E8";
    }
}

export function expandValue(value: "yes" | "res" | "no"): string {
    const normalizedValue = value?.toLowerCase();
    switch (normalizedValue) {
        case "yes":
            return "Yes";
        case "res":
            return "Restricted";
        case "no":
            return "No";
        default:
            return "N/A";
    }
}
interface CustomProps {
    children?: ReactNode;
}

type Props = MapLegendItemProps & CustomProps;

const MapLegendItem: React.FC<Props> = ({ color, children }) => (
    <div className={`${baseClass}__map-legend__item`}>
        <div className={`${baseClass}__map-legend__item-color-box`} style={{ backgroundColor: color }} />
        <span>{children}</span>
    </div>
);

const DistributionMap: React.FC<{
    mapDataset: Record<"type" | "arcs" | "transform" | "objects", number[] | string | object>;
    registrations: Record<string, number | string>;
    continent: string;
}> = ({ mapDataset, registrations, continent }): React.ReactElement => {
    const [tooltipCountry, setTooltipCountry] = useState(null);
    const [projectionConfig, setProjectionConfig] = useState(projections.europe);
    const [position, setPosition] = useState<Position>({ coordinates: [20, 52], zoom: 1 });

    const setMapStyleByContinent = (geo: { properties: Country }): Record<"default" | "hover" | "pressed", Record<"fill" | "stroke", string>> => {
        const selectedContinent = geo.properties.CONTINENT === continent;
        const fillColor = getFillColorForMarketing(registrations[`marketing${geo.properties.ISO_A2}`] as string);
        const obj = {
            default: {
                fill: selectedContinent ? fillColor : "white",
                stroke: "white",
            },
            hover: {
                fill: selectedContinent ? fillColor : "white",
                stroke: selectedContinent ? "black" : "white",
            },
            pressed: {
                fill: selectedContinent ? fillColor : "white",
                stroke: selectedContinent ? "black" : "white",
            },
        };
        return obj;
    };

    function handleZoomIn(): void {
        if (position.zoom >= 5) {
            return;
        }
        setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }));
    }

    function handleZoomOut(): void {
        if (position.zoom <= 1) {
            return;
        }
        setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }));
    }

    function handleMoveEnd(position): void {
        setPosition(position);
    }

    useEffect(() => {
        if (continent === "Asia") {
            setProjectionConfig(projections.asia);
            setPosition(pos => ({ ...pos, coordinates: [90, 25] }));
        } else if (continent === "South America") {
            setProjectionConfig(projections.south_america);
            setPosition(pos => ({ ...pos, coordinates: [-60, -22] }));
        } else {
            setProjectionConfig(projections.europe);
            setPosition(pos => ({ ...pos, coordinates: [20, 52] }));
        }
    }, [continent]);

    return (
        <div className={`${baseClass}__map`}>
            <div className={`${baseClass}__controls`}>
                <Icon inline name="zoom-in" size="large" className={`${baseClass}__controls__icon`} onClick={handleZoomIn} />
                <Icon inline name="zoom-out" size="large" className={`${baseClass}__controls__icon`} onClick={handleZoomOut} />
            </div>
            <ComposableMap className={`${baseClass}__map-content`} data-tip="" projection="geoMercator" projectionConfig={projectionConfig}>
                <ZoomableGroup zoom={position.zoom} center={position.coordinates} onMoveEnd={handleMoveEnd}>
                    <Geographies geography={mapDataset}>
                        {({ geographies }): React.ReactElement[] =>
                            geographies.map(
                                (geo: Record<string, string | Country>): React.ReactElement => (
                                    <Geography
                                        key={geo.rsmKey as string}
                                        geography={geo}
                                        className="my-anchor-element"
                                        onMouseEnter={(): void => setTooltipCountry(geo.properties["CONTINENT"] === continent ? geo.properties : null)}
                                        onMouseLeave={(): void => setTooltipCountry(null)}
                                        /* @ts-ignore */
                                        style={setMapStyleByContinent(geo)}
                                    />
                                ),
                            )
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>

            <div className={`${baseClass}__map-legend`}>
                <MapLegendItem color="#7d8f80">Marketing Distribution: Yes</MapLegendItem>
                <MapLegendItem color="#d89f5a">Marketing Distribution: Restricted</MapLegendItem>
                <MapLegendItem color="#B0B0B0">Marketing Distribution: No</MapLegendItem>
            </div>
            <Tooltip className={`${baseClass}__tooltip`} anchorSelect=".my-anchor-element" positionStrategy="fixed">
                {tooltipCountry && (
                    <Card>
                        <CardHeader>
                            <span>{tooltipCountry.NAME}</span>
                        </CardHeader>
                        <CardContent>
                            {/* @ts-ignore */}
                            <span>Legal Registration: {expandValue(registrations[`legal${tooltipCountry.ISO_A2}`])}</span>
                            <br />
                            {/* @ts-ignore */}
                            <span>Marketing Distribution: {expandValue(registrations[`marketing${tooltipCountry.ISO_A2}`])} </span>
                        </CardContent>
                    </Card>
                )}
            </Tooltip>
        </div>
    );
};

export default DistributionMap;
