import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { UIContextProvider } from "@src/common/Context";
import { AppUserContextProvider, NotificationContextProvider, IncomingPathContextProvider } from "@src/common/Context";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import { client } from "@src/common/Context/GQLContext";
import { InactiveUsersContextProvider } from "./common/Context/InactiveUsersContext";

const root = createRoot(document.getElementById("root"));

root.render(
    <ApolloProvider client={client}>
        <UIContextProvider>
            <IncomingPathContextProvider>
                <InactiveUsersContextProvider>
                    <AppUserContextProvider>
                        <NotificationContextProvider>
                            <Router>
                                <App />
                            </Router>
                        </NotificationContextProvider>
                    </AppUserContextProvider>
                </InactiveUsersContextProvider>
            </IncomingPathContextProvider>
        </UIContextProvider>
    </ApolloProvider>,
);
