import React, { ReactNode } from "react";

import Layout from "@components/containers/Layout";
import Logo from "@components/common/Logo";

import "./ErrorLayout.scss";

const baseClass = "acl-layout--error";

interface Props {
    children?: ReactNode;
}

const ErrorLayout: React.FC<Props> = ({ children }) => (
    <Layout className={`${baseClass}`}>
        <header className={`${baseClass}__header`}>
            <Logo isAuth />
        </header>
        {children}
    </Layout>
);

export default ErrorLayout;
