import React, { ReactNode } from "react";
import classNames from "classnames";

import { useUIContext } from "@src/common/Context";

import "./Layout.scss";

interface Props {
    className: string;
    children?: ReactNode;
}

const Layout: React.FC<Props> = ({ children, className }) => {
    const [context, _setContext] = useUIContext();

    return (
        <div className={classNames(`acl-layout`, { [className]: className })} aria-expanded={context.isSidebarOpen}>
            {children}
        </div>
    );
};

export default Layout;
