import React, { useState, useEffect } from "react";
import { publish } from "pubsub-js";
import { useLazyQuery, useMutation } from "@apollo/client";
import ReCAPTCHA from "react-google-recaptcha";

import { Grid, GridCellProps, GridColumn, GridColumnProps, GridFilterChangeEvent } from "@progress/kendo-react-grid";
import { filterBy, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Button, Chip, ChipList } from "@progress/kendo-react-buttons";

import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { RECAPTCHA_KEY, emailCampaigns, shouldDisableCaptcha } from "@src/common/config";
import {
    GET_EMPLOYEES,
    REQUEST_NEW_USER_MUTATION,
    REQUEST_REMOVE_USER_MUTATION,
    SEND_MAIL_MUTATION,
    SEND_INSTRUCTION_MAIL_MUTATION,
    UPDATE_USER_ROLES_MUTATION,
} from "@src/common/graphql";
import { useWindowDimensions } from "@src/common/hooks";
import { userStatusMap } from "@src/common/consts";
import { ERole, IRole, IUser, PSChannel } from "@src/common/types";
import { getInitials } from "@src/common/util";
import { BackToTop, LoadingPanel, PromptDialog, Icon, Card, CardHeader, CardContent } from "@components/common";
import ResponsiveTable from "@components/common/ResponsiveTable";
import Page, { Title } from "@components/containers/Page";

import UserForm from "./UserForm";
import "./UserManagement.scss";
import classNames from "classnames";
import { Switch } from "@progress/kendo-react-inputs";
import { useInactiveUsersContext } from "@src/common/Context/InactiveUsersContext";
import { errors } from "@src/common/errors";

const baseClass = "acl-page-user-management";

interface IState {
    users: IUser[];
    filteredUsers: IUser[];
    filter: CompositeFilterDescriptor;
}

const initialState: IState = {
    users: [],
    filteredUsers: [],
    filter: undefined,
};

type SortedRoles = {
    text: string;
    value: string;
    disabled: boolean;
};

type TransformedUser = {
    activated?: string;
    email: string;
    jobTitle: string;
    companyName?: string;
    statusCode?: number;
    roles: IRole[];
    formattedStatus: string;
    name: string;
    firstName: string;
    lastName: string;
    filterRoles: string;
};

export const sortRoles = (roles: IRole[]): SortedRoles[] =>
    roles.map(role => ({ text: role.name.replace("Portal ", ""), value: role.code, disabled: true })).sort((a, b) => (a.text > b.text ? 1 : -1));
export const transformUsers = (data: { employees: IUser[] }): TransformedUser[] =>
    data?.employees
        ?.map(({ activated, statusCode, firstName, lastName, roles, ...other }) => {
            const filterRoles = roles.map(role => role.name.replace("Portal ", "")).join(" ");
            return {
                ...other,
                activated: activated ? "Yes" : "No",
                statusCode,
                formattedStatus: userStatusMap[statusCode][0],
                name: `${firstName ? firstName + " " : ""} ${lastName ? lastName : ""}`,
                firstName,
                lastName,
                roles,
                filterRoles,
            };
        })
        .sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

const UserManagement: React.FC<{}> = () => {
    const [appUser, setAppUser] = useAppUserContext();
    const [state, setState] = useState<IState>(initialState);
    const [createUser, setCreateUser] = useState(false);
    const [editRoles, setEditRoles] = useState(false);
    const [selectedUser, setSelectedUser] = useState<IUser>(null);
    const [deleteUser, setDeleteUser] = useState(false);
    const [isDisabledForm, setIsDisabledForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showInactiveUsers, setShowInactiveUsers] = useInactiveUsersContext();
    const { sendNotification } = useNotificationContext();
    const { width } = useWindowDimensions();
    const recaptchaRef = React.useRef(null);

    const isPortalAdmin = appUser.roles.includes(ERole.PortalAdmin);

    const toggleActiveUsers = (usersList: IUser[]): IUser[] => usersList.filter(user => (showInactiveUsers ? user : user.roles.length > 0));

    const filterChange = (event: GridFilterChangeEvent): void => {
        setState({
            ...state,
            filteredUsers: filterBy(toggleActiveUsers(state.users), event.filter),
            filter: event.filter,
        });
    };

    const onClose = (): void => setCreateUser(false);

    const onCloseEditRoles = (): void => {
        setEditRoles(false);
        setSelectedUser(null);
    };

    const onCancelDeleteUser = (): void => {
        setDeleteUser(false);
        setSelectedUser(null);
    };

    const deleteUserHandler = (user: IUser): void => {
        setDeleteUser(true);
        setSelectedUser(user);
    };

    const createUserHandler = (): void => {
        setCreateUser(true);
    };

    const editRolesHandler = (user: IUser): void => {
        setEditRoles(true);
        setSelectedUser(user);
    };

    const [getEmployees, { loading: loadingEmployees, error: errorEmployees, data: dataEmployees, refetch: refetchEmployees }] = useLazyQuery(GET_EMPLOYEES, {
        variables: { companyId: appUser.companyId },
        onCompleted: data => {
            const users = transformUsers(data);
            setState({
                ...state,
                users,
                filteredUsers: toggleActiveUsers(users),
            });
        },
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }
        getEmployees().catch(error => console.error(error));
    }, []);

    const [updateUserRoles] = useMutation(UPDATE_USER_ROLES_MUTATION);

    const onConfirmEditRoles = (roles: string[]): void => {
        setIsDisabledForm(true);

        if (!selectedUser?.email) {
            publish(PSChannel.Error, `There is no email address for this user in our database yet.\nPlease get in touch with your CRM contact.`);
            setEditRoles(false);
            setSelectedUser(null);
            setIsDisabledForm(false);
            return;
        }

        updateUserRoles({
            variables: { email: selectedUser.email, roles },
            onError: error => {
                setEditRoles(false);
                setSelectedUser(null);
                setIsDisabledForm(false);
            },
            onCompleted: data => {
                refetchEmployees({ companyId: appUser.companyId });
                sendNotification({
                    timeout: 12000,
                    type: "success",
                    message: "User roles are successfully changed",
                });
                if (selectedUser.email === appUser.email) {
                    setAppUser({ ...appUser, roles });
                }
                setEditRoles(false);
                setSelectedUser(null);
                setIsDisabledForm(false);
            },
        });
    };

    const [requestNewUser] = useMutation(REQUEST_NEW_USER_MUTATION);
    const [sendMail] = useMutation(SEND_MAIL_MUTATION, {
        onCompleted: data => {
            console.warn("Email sent.");
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        },
        onError: error => {
            console.error("Email sending failed: ", error.message);
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        },
    });

    const [sendInstructionMail] = useMutation(SEND_INSTRUCTION_MAIL_MUTATION);
    const onConfirmCreateUser = async (user: IUser): Promise<void> => {
        setIsDisabledForm(true);

        const newUser = {
            ...user,
            companyId: appUser.companyId,
            requestedBy: appUser.email,
        };

        if (state.filteredUsers.find(user => user.email === newUser.email)) {
            /* @TODO
             * Extend publish error with option to publish JSX
             * publish(PSChannel.Error, <div> ... </div>);
             */
            sendNotification({
                timeout: 10000,
                type: "error",
                message: (
                    <div>
                        The user already exists in the All users list.
                        <br />
                        Click on
                        <Icon title="Edit roles" name="pencil" spacing="both" />
                        to edit its access roles.
                    </div>
                ),
            });
            setCreateUser(false);
            setIsDisabledForm(false);
            showInactiveUsers ? setShowInactiveUsers(showInactiveUsers) : setShowInactiveUsers(!showInactiveUsers);
        } else {
            const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
            const message = `Account: ${appUser.companyName}\nName: ${user.firstName}\nLast Name: ${user.lastName}\nEmail: ${user.email}\nPosition: ${user.jobTitle}`;
            const sendMailVariables = {
                recaptcha,
                campaign: emailCampaigns.CreateUser,
                params: {
                    Sender: appUser.email,
                    Text: message,
                },
            };

            requestNewUser({
                variables: { newUser },
                onError: error => {
                    setIsDisabledForm(false);
                },
                onCompleted: data => {
                    setIsDisabledForm(false);
                    refetchEmployees({ companyId: appUser.companyId });
                    sendNotification({
                        timeout: 12000,
                        type: "success",
                        message: "The request for a new user has been sent.",
                    });
                    setCreateUser(false);
                    sendMail({
                        variables: sendMailVariables,
                    });
                    sendInstructionMail({
                        variables: { email: newUser.email },
                        onError: error => {
                            setIsDisabledForm(false);
                        },
                        onCompleted: data => {
                            setIsDisabledForm(false);
                            setCreateUser(false);
                        },
                    });
                },
            });
        }
    };

    const [requestRemoveUser, { loading: loadingRequestRemoveUser, error: errorRequestRemoveUser, data: dataRequestRemoveUser }] =
        useMutation(REQUEST_REMOVE_USER_MUTATION);

    const onConfirmDeleteUser = async (): Promise<void> => {
        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        const message = `Account: ${appUser.companyName}\nEmail: ${selectedUser.email}\n\n`;
        const sendMailVariables = {
            recaptcha,
            campaign: emailCampaigns.DeleteUser,
            params: {
                Sender: appUser.email,
                Text: message,
            },
        };

        setIsDisabledForm(true);

        requestRemoveUser({
            variables: { email: selectedUser.email, requestedBy: appUser.email },
            onError: error => {
                setIsDisabledForm(false);
                setDeleteUser(false);
                setSelectedUser(null);
            },
            onCompleted: data => {
                setDeleteUser(false);
                setSelectedUser(null);
                sendNotification({
                    timeout: 12000,
                    type: "success",
                    message: `The user is pending for deletion`,
                });
                refetchEmployees({ companyId: appUser.companyId });
                setIsDisabledForm(false);
                sendMail({
                    variables: sendMailVariables,
                });
            },
        });
    };

    const ImageCell: React.FC<GridCellProps> = ({ dataItem: { firstName, lastName } }) => (
        <td>
            <div className={`${baseClass}__userManagementAvatar`}>{getInitials(`${firstName ? firstName : ""} ${lastName ? lastName : ""}`)}</div>
        </td>
    );

    const UserCell: React.FC<GridCellProps> = ({ dataItem: { name, jobTitle, email } }) => (
        <td title={email}>
            <h4 className={`${baseClass}__user-name`}>{name}</h4>
            <small className={`${baseClass}__user-roles`}>{jobTitle}</small>
        </td>
    );

    const StatusCell: React.FC<GridCellProps> = ({ dataItem: { statusCode, formattedStatus } }) => (
        <>
            <td>
                <Chip
                    className={classNames(`${baseClass}__status`, `${baseClass}__status--${userStatusMap[statusCode][1]}`)}
                    text={formattedStatus}
                    themeColor={userStatusMap[statusCode][1]}
                    value="chip"
                    fillMode={"solid"}
                />
                <a data-tooltip-id="tooltip" data-tooltip-content={userStatusMap[statusCode][2]} data-tooltip-place={width > 1024 ? "left" : "top"}>
                    <Icon className={`${baseClass}__tooltip`} name="question-circle" />
                </a>
            </td>
        </>
    );

    const RolesCell: React.FC<GridCellProps> = ({ dataItem: { roles } }) => (
        <td>
            <ChipList selection={null} defaultData={sortRoles(roles)} />
        </td>
    );

    const RegisteredCell: React.FC<GridCellProps> = ({ dataItem: { activated, email } }) => (
        <td style={{ textAlign: "center" }}>
            <div className={`${baseClass}__registeredCell`}>
                {activated.toLowerCase() === "yes" ? (
                    <Chip
                        className={classNames(`${baseClass}__status`, `${baseClass}__status--${"success"}`)}
                        text={"Yes"}
                        themeColor={"success"}
                        value="chip"
                        fillMode={"solid"}
                    />
                ) : (
                    <Button
                        size="small"
                        onClick={() => {
                            sendInstructionMail({
                                variables: { email },
                                onError: error => {
                                    sendNotification({
                                        timeout: 12000,
                                        type: "error",
                                        message: "Sending instructions failed. Please try again later.",
                                    });
                                },
                                onCompleted: data => {
                                    sendNotification({
                                        timeout: 12000,
                                        type: "success",
                                        message: "Sign-up instructions sent to user",
                                    });
                                },
                            });
                        }}
                    >
                        No | Send instructions
                    </Button>
                )}
            </div>
        </td>
    );

    const ActionsCell: React.FC<GridCellProps> = ({ dataItem: user }) => (
        <td className={`${baseClass}__main__grid__actions`}>
            <Icon title="Edit roles" name="pencil" spacing="both" onClick={(): void => editRolesHandler(user)} />
            <Icon title="Delete user" name="trash" spacing="both" onClick={(): void => deleteUserHandler(user)} />
        </td>
    );

    const columns: GridColumnProps[] = [
        { field: "", title: "", cell: ImageCell, width: 48, filterable: false },
        { field: "name", title: "User", cell: UserCell },
        { field: "filterRoles", title: "Active Access Roles", cell: RolesCell },
    ];

    if (isPortalAdmin) {
        columns.push(
            { field: "formattedStatus", title: "User Contact Status", cell: StatusCell },
            { field: "activated", title: "Sign-up Complete", cell: RegisteredCell },
            { field: "", title: "", cell: ActionsCell, width: 80, filterable: false },
        );
    }

    useEffect(() => {
        setLoading(loadingEmployees);
    }, [loadingEmployees]);

    useEffect(() => {
        setState({
            ...state,
            filteredUsers: filterBy(toggleActiveUsers(state.users), state.filter),
        });
    }, [showInactiveUsers]);

    return (
        <Page className={baseClass}>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>User Management</Title>
            </header>
            <div className={`${baseClass}__main`}>
                <Card className={`${baseClass}__list`}>
                    {loading ? (
                        <div className={`${baseClass}__loading-wrapper`}>
                            <LoadingPanel />
                        </div>
                    ) : (
                        <>
                            <CardHeader className={`${baseClass}__main__header`}>
                                <div className={`${baseClass}__switch`}>
                                    <Switch
                                        onLabel=""
                                        offLabel=""
                                        id="twoFASwitch"
                                        checked={!showInactiveUsers}
                                        onChange={(): void => setShowInactiveUsers(!showInactiveUsers)}
                                        disabled={loading}
                                    />
                                    <label>{showInactiveUsers ? "All users" : "Portal users only"}</label>
                                    <a
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content={
                                            showInactiveUsers ? "All contacts of your company we have stored in our database." : "Users with Portal access"
                                        }
                                        data-tooltip-place="right"
                                    >
                                        <Icon className={`${baseClass}__tooltip`} name="question-circle" />
                                    </a>
                                </div>
                                {isPortalAdmin && (
                                    <Button
                                        onClick={() => {
                                            if (!appUser.companyId) {
                                                publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
                                                return;
                                            }
                                            createUserHandler();
                                        }}
                                        themeColor="primary"
                                    >
                                        Request New User
                                    </Button>
                                )}
                            </CardHeader>
                            <CardContent>
                                <ResponsiveTable>
                                    <Grid
                                        data={state.filteredUsers}
                                        sortable={false}
                                        scrollable="none"
                                        filterable={true}
                                        filter={state.filter}
                                        onFilterChange={filterChange}
                                        className={`${baseClass}__main__grid`}
                                    >
                                        {columns.map(column => (
                                            <GridColumn key={column.title} field={column.field} {...column} />
                                        ))}
                                    </Grid>
                                </ResponsiveTable>
                            </CardContent>
                        </>
                    )}
                    {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
                </Card>
                {createUser && <UserForm onClose={onClose} isDisabled={isDisabledForm} onCreate={(user: IUser): Promise<void> => onConfirmCreateUser(user)} />}
                {editRoles && (
                    <UserForm
                        onClose={onCloseEditRoles}
                        isDisabled={isDisabledForm}
                        onEdit={(roles: string[]): void => onConfirmEditRoles(roles)}
                        user={selectedUser}
                    />
                )}
                {deleteUser && (
                    <PromptDialog
                        isDisabled={isDisabledForm}
                        title="Delete user"
                        onReject={onCancelDeleteUser}
                        onConfirm={onConfirmDeleteUser}
                        isLoading={loadingRequestRemoveUser}
                    >
                        <p>Confirm only if the user is no longer with the company.</p>
                        <p>
                            To manage the User list and individual access privileges, use “Show/Hide users” filter or “Edit roles”.{" "}
                            <Icon title="Edit roles" name="pencil" spacing="both" />
                        </p>
                        <p>
                            NOTE:
                            <br />
                            Users with no roles cannot access the Portal.
                        </p>
                    </PromptDialog>
                )}
            </div>
            <BackToTop />
        </Page>
    );
};

export default UserManagement;
