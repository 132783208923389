import React from "react";
import { Card, CardContent, Link } from "@components/common";

import "./SignupSuccessful.scss";

const baseClass = "acl-page-auth";

const SignupSuccessful: React.FC<{}> = () => (
    <main className={`${baseClass} ${baseClass}--login`}>
        <div className={`${baseClass}__success-wrapper`}>
            <h1>Client Portal</h1>
            <Card className={`${baseClass}__content`}>
                <CardContent>
                    <h3 className={`${baseClass}__title`}>Email Address Verification</h3>
                    <p>Thank you for your registration. To verify your email address please check your inbox and click on the verification link.</p>
                    <div className={`k-form-buttons ${baseClass}__actions`}>
                        <Link to="/faq" type="button" primary>
                            Back to Login
                        </Link>
                    </div>
                </CardContent>
            </Card>
        </div>
    </main>
);

export default SignupSuccessful;
