import React, { ReactNode } from "react";

import "./ResponsiveTable.scss";

interface Props {
    children?: ReactNode;
}

const ResponsiveTable: React.FC<Props> = ({ children }) => <div className="responsive-table">{children}</div>;

export default ResponsiveTable;
