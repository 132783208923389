import React, { ReactNode } from "react";
import { NavLink as RouterLink, LinkProps } from "react-router-dom";
import classNames from "classnames";

import Icon from "@components/common/Icon";
import { isURL } from "@src/common/util";

import "./Link.scss";

type TLinkTypes = "button" | "text";
type TLinkShapes = "round";

interface IProps extends LinkProps {
    type?: TLinkTypes;
    shape?: TLinkShapes;
    icon?: string;
    tag?: string;
    primary?: boolean;
    className?: string;
    active?: boolean;
    disabled?: boolean;
    children?: ReactNode;
    size?: "small" | "medium" | "large";
}

const baseClass = "acl-link";

const Link: React.FC<IProps> = (props: IProps) => {
    const { type, shape, icon, tag, className, children, to, active, primary, size, ...rest } = props;

    const isNative = typeof to === "string" && isURL(to);

    // prettier-ignore
    const classes: string = classNames(
        baseClass,
        type && `${baseClass}--${type}`,
        type === "button" && "k-button",
        primary && "k-primary",
        size && `${baseClass}--${size}`,
        shape && `${baseClass}--${shape}`,
        icon && `${baseClass}--icon`,
        active && `${baseClass}--active`,
        className,
    );

    return isNative ? (
        <a className={classes} href={to.toString()} {...rest}>
            {icon && <Icon name={icon} />}
            {tag && <sup className={`${baseClass}__tag`}>{tag}</sup>}
            {children}
        </a>
    ) : (
        <RouterLink className={classes} to={to} {...rest}>
            {icon && <Icon name={icon} />}
            {tag && <sup className={`${baseClass}__tag`}>{tag}</sup>}
            {children}
        </RouterLink>
    );
};

export default Link;
