import React, { useEffect, useState } from "react";

import { Grid, GridColumn as Column, GridCellProps, GridFilterChangeEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { process, SortDescriptor, filterBy, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Chip } from "@progress/kendo-react-buttons";

import axios from "@src/common/http";
import { API_ENDPOINT } from "@src/common/config";
import { getFilenameFromContentDisposition, mapStatusClassToColor } from "@src/common/util";
import { useWindowDimensions } from "@src/common/hooks";
import LoadingPanel from "@components/common/LoadingPanel";
import Icon from "@components/common/Icon";
import ResponsiveTable from "@components/common/ResponsiveTable";

import "./DocumentLoads.scss";

const baseClass = "acl-recent-fund-documents";

interface IDocument {
    documentId: number;
    companyId: number;
    fileName: string;
    type: string;
    importedDate: string;
    asOfDate: string;
    status: string;
    countries: string;
    languages: string;
}

interface IState {
    filteredDocuments: IDocument[];
    dataState: {
        sort: SortDescriptor[];
        take: number;
        skip: number;
    };
    filter: CompositeFilterDescriptor;
}

const initialState: IState = {
    filteredDocuments: [],
    dataState: {
        sort: [{ field: "importedDate", dir: "desc" }],
        take: 10,
        skip: 0,
    },
    filter: undefined,
};

const FileNameCell: React.FC<GridCellProps> = ({ dataItem }) => {
    const { fileName, documentId } = dataItem;
    const [downloading, setDownloading] = useState<boolean>(false);

    const handleDownload = (): void => {
        setDownloading(true);

        axios
            // .get(`${API_ENDPOINT}/portal/fund-document?docId=${documentId}`, { responseType: "blob" })
            .get(`${API_ENDPOINT}/fund-document?docId=${documentId}`, { responseType: "blob" })
            .then(response => {
                if (response.status !== 200) {
                    setDownloading(false);
                    return;
                }
                // Get original file name from header
                const header = decodeURI(response.headers["content-disposition"]);
                const filename = getFilenameFromContentDisposition(header, "fund-document.pdf");

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                setDownloading(false);
            })
            .catch(error => {
                setDownloading(false);
                console.error(error);
            });
    };

    return (
        <td>
            <div onClick={handleDownload} className={`${baseClass}__filename`}>
                <Icon name={downloading ? "loading" : "download-bottom"} spacing="right" inline color="secondary" />
                {fileName}
            </div>
        </td>
    );
};

const StatusCell: React.FC<GridCellProps> = ({ dataItem }) => {
    const { width } = useWindowDimensions();
    return (
        <>
            <td>
                <Chip
                    text={dataItem.formattedStatus}
                    value="chip"
                    fillMode={"solid"}
                    icon={"k-i-acl-icon acl-i-notes-book-text"}
                    themeColor={mapStatusClassToColor(dataItem.statusClass)}
                    className={`${baseClass}__status ${baseClass}__status--${dataItem.statusClass}`}
                />
                <a data-tooltip-id="tooltip" data-tooltip-content={dataItem.statusInfo} data-tooltip-place={width > 1024 ? "left" : "top"}>
                    <Icon className={`${baseClass}__tooltip`} name="question-circle" />
                </a>
            </td>
        </>
    );
};

const CountriesCell: React.FC<GridCellProps> = ({ dataItem }) => <td className={`${baseClass}__countriesCell`}>{dataItem.countries}</td>;

const columns = [
    { field: "fileName", title: "Name", cell: FileNameCell },
    { field: "type", title: "Type", width: 76 },
    { field: "languages", title: "Language", width: 76 },
    { field: "countries", title: "Countries", width: 220, cell: CountriesCell },
    { field: "importedDate", title: "Imported Date", width: 120 },
    { field: "asOfDate", title: "As of Date", width: 120 },
    { field: "formattedStatus", title: "Status", width: 175, cell: StatusCell },
];

const Documentloads: React.FC<{ documents: IDocument[]; loading: boolean }> = ({ documents, loading }) => {
    const [state, setState] = useState<IState>({ ...initialState, filteredDocuments: documents });

    useEffect(() => {
        setState(prevState => ({ ...prevState, filteredDocuments: prevState.filter ? filterBy(documents, prevState.filter) : documents }));
    }, [documents]);

    const filterChange = (event: GridFilterChangeEvent): void => {
        setState({
            ...state,
            dataState: { ...state.dataState, skip: 0 },
            filteredDocuments: filterBy(documents, event.filter),
            filter: event.filter,
        });
    };

    const sortChange = (event: GridSortChangeEvent): void => {
        setState({
            ...state,
            dataState: { ...state.dataState, sort: event.sort },
        });
    };

    const onDataStateChange = ({ dataState }): void =>
        setState({
            ...state,
            dataState,
        });

    return (
        <div className={baseClass}>
            <ResponsiveTable>
                <Grid
                    data={process(state.filteredDocuments, state.dataState)}
                    sortable={true}
                    sort={state.dataState.sort}
                    take={state.dataState.take}
                    skip={state.dataState.skip}
                    scrollable="none"
                    filterable={true}
                    filter={state.filter}
                    onFilterChange={filterChange}
                    onSortChange={sortChange}
                    pageable={{
                        buttonCount: 5,
                        info: true,
                        type: "input",
                        pageSizes: false,
                        previousNext: true,
                    }}
                    onDataStateChange={onDataStateChange}
                >
                    {columns.map(({ field, ...other }) => (
                        <Column key={field} field={field} {...other} />
                    ))}
                </Grid>
            </ResponsiveTable>
            {loading && <LoadingPanel />}
        </div>
    );
};

export default Documentloads;
