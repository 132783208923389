import React from "react";
import classNames from "classnames";

import { DatePicker } from "@progress/kendo-react-dateinputs";

import "./DateRangeFilterCell.scss";

const baseClass = "acl-page-date-range-filter-cell";
interface IProps {
    min: Date;
    max: Date;
    isCompact?: boolean;
    onDateFilterChange({ value, operator }: { value: Date; operator: string }): void;
}

const DateRangeFilterCell: React.FC<IProps> = props => (
    <div className={classNames(`${baseClass}`, `${baseClass}__compact-view`)}>
        <DatePicker
            value={props.min}
            format={"yyyy-MM-dd"}
            placeholder="From"
            onChange={(e): void => {
                props.onDateFilterChange({
                    value: e.target.value,
                    operator: "gte",
                });
            }}
        />
        <DatePicker
            value={props.max}
            format={"yyyy-MM-dd"}
            placeholder="To"
            onChange={(e): void => {
                // const formatedDate = add(e.target.value, { hours: 20 });
                props.onDateFilterChange({
                    value: e.target.value,
                    operator: "lte",
                });
            }}
        />
    </div>
);

export default DateRangeFilterCell;
