/* eslint-disable no-mixed-operators */
import { isEqualDate } from "@progress/kendo-date-math";
import { CountryCodesByContinent, CountryInfos, IDate } from "@src/common/types";
import { format, endOfMonth, subMonths, addMonths, addDays, isWeekend, subDays } from "date-fns";
import Countries, { countryCodesByContinent } from "./countries";

export const requiredValidator = (value: string): string => (value && value.length ? "" : "This field is required");

export const emailValidator = (value: string): string => (/\S+@\S+\.\S+/.test(value) ? "" : "Please enter a valid email address.");

export const passwordValidator = (value: string): string => (value && value.trim() !== "" ? "" : "Password cannot be blank.");

export const samePasswordValidator = (oldPassword: string, newPassword: string): string =>
    oldPassword !== newPassword ? "" : "New password should not be the same as the old password.";

export const passwordLengthValidator = (value: string): string => (value && value.length > 7 ? "" : "Your password must be at least 8 characters long.");

export const repeatPasswordValidator = (password: string, repeatPassword: string): string => (password === repeatPassword ? "" : "Passwords must match.");

export const saveDownload = (fileName: string, data: Buffer, options?: { isNewTab?: boolean; type?: string }): boolean => {
    if (!data) {
        return false;
    }

    const url = window.URL.createObjectURL(new Blob([data], options.type ? { type: options.type } : undefined));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", fileName);
    link.setAttribute("target", options.isNewTab ? "_blank" : "_self");
    document.body.appendChild(link);
    link.click();
    link.remove();

    return true;
};

export const getInitials = (name: string): string =>
    name
        .replace(/[^a-zA-Z- ]/g, "")
        .match(/\b\w/g)
        ?.join("");

export const getPercentage = (value: number | string): string => (Number(value) * 100).toFixed(2) + "%";

export const isURL = (url: string): boolean => {
    try {
        new URL(url);
    } catch (e) {
        return false;
    }

    return true;
};

export const toTop = (): void => {
    window.scrollTo({ top: 0, behavior: "smooth" });
};

export const getEaster = (y: number): Date => {
    // Instantiate the date object.
    const date = new Date();

    // Set the timestamp to midnight.
    date.setHours(0, 0, 0, 0);

    // Set the year.
    date.setFullYear(y);

    // Find the golden number.
    const a = y % 19;

    // Choose which version of the algorithm to use based on the given year.
    const b = 2200 <= y && y <= 2299 ? (11 * a + 4) % 30 : (11 * a + 5) % 30;

    // Determine whether or not to compensate for the previous step.
    const c = b === 0 || (b === 1 && a > 10) ? b + 1 : b;

    // Use c first to find the month: April or March.
    const m = 1 <= c && c <= 19 ? 3 : 2;

    // Then use c to find the full moon after the northward equinox.
    const d = (50 - c) % 31;

    // Mark the date of that full moon—the "Paschal" full moon.
    date.setMonth(m, d);

    // Count forward the number of days until the following Sunday (Easter).
    date.setMonth(m, d + (7 - date.getDay()));

    // Gregorian Western Easter Sunday
    return date;
};

const getHolidayDates = (currentYear: number): Date[] => {
    const dates = [];

    //New Years day, 1. Jan
    const newYearsDayDate = new Date(`1-1-${currentYear}`);
    if (!isWeekend(newYearsDayDate)) {
        dates.push(newYearsDayDate);
    }
    //Berchtold's day, 2. Jan
    const berchtoldDayDate = new Date(`1-2-${currentYear}`);
    if (!isWeekend(berchtoldDayDate)) {
        dates.push(berchtoldDayDate);
    }
    //Labor Day, 1. May
    const laborDay = new Date(`5-1-${currentYear}`);
    if (!isWeekend(laborDay)) {
        dates.push(laborDay);
    }
    //Swiss National Day, 1. Aug
    const swissNationalDay = new Date(`8-1-${currentYear}`);
    if (!isWeekend(swissNationalDay)) {
        dates.push(swissNationalDay);
    }
    //Christmass Day, 25. Dec
    const xmasDay = new Date(`12-25-${currentYear}`);
    if (!isWeekend(xmasDay)) {
        dates.push(xmasDay);
    }
    //St. Stephen's Day, 26. Dec
    const stStephenDay = new Date(`12-26-${currentYear}`);
    if (!isWeekend(stStephenDay)) {
        dates.push(stStephenDay);
    }

    const easterDate = getEaster(currentYear);
    //Good Friday, falls on ..
    dates.push(addDays(easterDate, -2));
    //Easter Monday, falls on ..
    dates.push(addDays(easterDate, 1));
    //Ascension Day, falls on Thursday
    dates.push(addDays(easterDate, 39));
    //Whit Monday, falls on ..
    dates.push(addDays(easterDate, 50));

    return dates;
};

export const calculateWorkdaysFromDate = (dateFrom: Date, operation: string, cutoff: number): Date => {
    if (operation !== "+" && operation !== "-") {
        throw "Invalid operation";
    }

    const dateCutoff = cutoff / 365 || 1;
    const holidayDates = [
        ...getHolidayDates(dateFrom.getFullYear() - 1),
        ...[...Array(Math.ceil(dateCutoff + 1)).keys()].map(addition => getHolidayDates(dateFrom.getFullYear() + addition)).flat(), // + 1, for the holidays of the next year
    ];

    let firstWorkday = dateFrom;

    while (isWeekend(firstWorkday) || holidayDates.find(holiday => isEqualDate(firstWorkday, holiday))) {
        if (operation === "+") {
            firstWorkday = addDays(firstWorkday, 1);
        } else if (operation === "-") {
            firstWorkday = subDays(firstWorkday, 1);
        }
    }

    let workdaysRemaining = cutoff;
    let selectedDate = firstWorkday;

    while (workdaysRemaining !== 0) {
        selectedDate = operation === "+" ? addDays(selectedDate, 1) : subDays(selectedDate, 1);
        if (!isWeekend(selectedDate) && !holidayDates.find(date => isEqualDate(date, selectedDate))) {
            if (workdaysRemaining < 0) {
                workdaysRemaining += 1;
            } else {
                workdaysRemaining -= 1;
            }
        }
    }

    return selectedDate;
};

export const generateQuarters = async (currentDate: Date = new Date()): Promise<IDate[]> => {
    // TO-DO Refactor logic for generating quarters in the next year
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const quartersList = [
        { name: "Q1", start: "01-01", end: "03-31", number: 1 },
        { name: "Q2", start: "04-01", end: "06-30", number: 2 },
        { name: "Q3", start: "07-01", end: "09-30", number: 3 },
        { name: "Q4", start: "10-01", end: "12-31", number: 4 },
    ];

    const pastYear = currentYear - 1;
    const nextYear = currentYear + 1;
    const currentQuarterNumber = Math.ceil(currentMonth / 3);

    const prevYearOptions = quartersList.map(quarter => ({ text: `${pastYear} ${quarter.name}`, year: pastYear, quarter: quarter.number }));
    const thisYearOptions = quartersList
        .filter(quarter => quarter.number <= currentQuarterNumber + 1)
        .map(quarter => ({ text: `${currentYear} ${quarter.name}`, year: currentYear, quarter: quarter.number }));
    const nextYearOptions = currentMonth > 9 ? [{ text: `${nextYear} Q1`, year: nextYear, quarter: 1 }] : [];

    const options = [...prevYearOptions, ...thisYearOptions, ...nextYearOptions].sort((a, b) => {
        const compareYears = b.year - a.year;
        return compareYears === 0 ? b.quarter - a.quarter : compareYears;
    });

    const currentQuarter = quartersList.find(quarter => quarter.number === currentQuarterNumber);
    const currentQuarterEndDate = new Date(`${currentQuarter.end}-${currentYear}`);

    const cutoffDate = calculateWorkdaysFromDate(currentQuarterEndDate, "-", 23);
    const slicedOptions = currentDate >= cutoffDate ? options.slice(0, 6) : options.slice(1, 6);
    return slicedOptions;
};

export const generateMonths = async (currentDate: Date = new Date()): Promise<IDate[]> => {
    const months = [];
    const endOfQuarterMonths = [3, 6, 9, 12];
    const currentDateMonthEnd = endOfMonth(currentDate);

    let showNextMonth = false;
    if (endOfQuarterMonths.includes(currentDate.getMonth() + 1)) {
        showNextMonth = currentDate >= calculateWorkdaysFromDate(currentDateMonthEnd, "-", 10);
    } else {
        showNextMonth = currentDate >= calculateWorkdaysFromDate(currentDateMonthEnd, "+", 2);
    }

    // Add next month
    if (showNextMonth) {
        const futureDate = addMonths(currentDate, 1);
        months.push({
            text: format(futureDate, "yyyy MMMM"),
            year: futureDate.getFullYear(),
            month: futureDate.getMonth() + 1,
        });
    }
    // Add current month
    months.push({
        text: format(currentDate, "yyyy MMMM"),
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
    });
    // Add 4 previous months
    for (let i = 0; i < 4; i++) {
        const pastDate = subMonths(currentDate, i + 1);
        months.push({
            text: format(pastDate, "yyyy MMMM"),
            year: pastDate.getFullYear(),
            month: pastDate.getMonth() + 1,
        });
    }

    return months;
};

// Return amount formatted with two decimals, and with commas
export const formatAmount = (amount: number): string =>
    (Math.round(amount * 100) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const checkFirstLetterSpace = (_string: string): boolean =>
    // Check if string starts with whitespace
    /^\s/.test(_string);

export const getFilenameFromContentDisposition = (content: string, defaultValue: string): string => {
    if (!content) {
        return defaultValue;
    }

    const parts = content.split(";");
    const filename = parts
        .map(part => {
            const [key, value] = part.trim().split("=");
            return [key, value];
        })
        .find(([key, value]) => key === "filename" && value);
    return filename?.[1].replaceAll(`"`, "") ?? defaultValue;
};

export const getRiskPercentageColor = (percentage: number): string => {
    if (Number.isNaN(percentage)) {
        return "inherit";
    } else if (percentage < 36) {
        return "#61bfad";
    } else if (percentage >= 36 && percentage <= 48) {
        return "#FFA00F";
    } else if (percentage > 48) {
        return "#e75152";
    }
};

export const mapStatusClassToColor = (status: string): "base" | "warning" | "error" | "success" | "info" => {
    switch (status) {
        case "pending":
            return "warning";
        case "error":
            return "error";
        case "success":
            return "success";
        case "aborted":
            return "base";
        default:
            return "base";
    }
};

export const getCountryCode = (country: string): string => Object.keys(Countries).find(code => Countries[code] === country);

export const findContinentByCountryCode = (countryCode: string): string | null => {
    for (const continent in countryCodesByContinent) {
        if (countryCodesByContinent[continent].includes(countryCode)) {
            return continent;
        }
    }
    return null;
};

export const getContinentsWithCountryNames = (countryInfos: CountryInfos[], countryCodesByContinent: CountryCodesByContinent): CountryCodesByContinent => {
    const result = {};

    Object.keys(countryCodesByContinent).forEach(continent => {
        const continentCountries = countryCodesByContinent[continent];
        result[continent] = countryInfos.filter(country => continentCountries.includes(country.isoa2code)).map(country => country.name);
    });

    return result;
};

export const calculatePercentage = (x: number, y: number): number => {
    const percent = (x / y) * 100;
    return Math.ceil(percent);
};

export const scrollHashElementIntoView = (id: string): void => {
    const element = document.getElementById(id);

    if (element) {
        const headerOffset = 45;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + scrollY - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });
        element.classList.add("highlighted");
        setTimeout(() => {
            element.classList.remove("highlighted");
        }, 8000);
    }
};
