import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import React, { useState } from "react";

import "./CountryDetails.scss";
import logoUrl from "@images/acolin-logo-no-strapline.svg";
import { Button } from "@progress/kendo-react-buttons";
import classNames from "classnames";
import { CountryInfo, Dp } from "../types";
import { Grid, GridColumn, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { Card, CardContent, Flag, ResponsiveTable, Rtf } from "@components/common";
import Navigation from "@components/common/Navigation";

const baseClass = "acl-country-details";

type TEvent = DialogCloseEvent | React.MouseEvent<HTMLElement, MouseEvent>;

interface IProps {
    countryInfo: CountryInfo;
    dps: Dp[];
    onClose(event: TEvent): void;
}

type RowProps = {
    label: string;
    value?: string | TrustedHTML;
    componentProp?: React.ReactNode;
};

const Row: React.FC<RowProps> = ({ label, value, componentProp }) =>
    (value || componentProp) && (
        <div className={`${baseClass}__dl-row`}>
            <dt>{label}</dt>
            <dd className={`${baseClass}__dl-row-value`}>{value ? <Rtf message={value} /> : componentProp}</dd>
        </div>
    );

const columns = [
    { field: "name", title: "Distributor" },
    { field: "city", title: "City" },
    { field: "category", title: "Category" },
];

const initialSort: Array<SortDescriptor> = [{ field: "name", dir: "asc" }];

export const CountryDetailCard: React.FC<{ countryDetailTitle: string; countryDetail: string }> = ({ countryDetailTitle, countryDetail }) => {
    if (!countryDetail) {
        return;
    }

    return (
        <Card className={classNames(`${baseClass}__content`, `${baseClass}__content-description`)}>
            <CardContent>
                <dl>
                    <Row label={countryDetailTitle} value={countryDetail} />
                </dl>
            </CardContent>
        </Card>
    );
};

const CountryDetails: React.FC<IProps> = ({ countryInfo, dps, onClose = (): void => undefined }) => {
    const onCloseDialog = (event: TEvent): void => onClose(event);
    const [selectedTab, setSelectedTab] = useState<"general" | "dps">("general");
    const [sort, setSort] = React.useState(initialSort);

    const routes = [
        { label: "General Information", isActive: selectedTab === "general" },
        { label: "Distributor Network", isActive: selectedTab === "dps" },
    ];

    return (
        <Dialog className={`${baseClass}__dialog`} onClose={onCloseDialog}>
            <>
                <div className={`${baseClass}__top-section`}>
                    <img className={`${baseClass}__logo`} src={logoUrl} alt="Acolin" />
                    <p>connecting you to opportunity</p>
                    <h1>
                        <Flag country={countryInfo.isoa2code} spacing="right" shape="circle" />
                        {countryInfo.name}
                    </h1>
                </div>
                <Navigation routes={routes} onClick={(label: string): void => setSelectedTab(label === "General Information" ? "general" : "dps")} />
                {selectedTab === "general" ? (
                    <>
                        <CountryDetailCard countryDetailTitle={"Country Facts"} countryDetail={countryInfo.countryFacts} />
                        <CountryDetailCard countryDetailTitle={"Registration"} countryDetail={countryInfo.registration} />

                        <div className={`${baseClass}__detail_section`}>
                            <CountryDetailCard countryDetailTitle={"Investitors and Distributors"} countryDetail={countryInfo.investorsAndDistributors} />
                            <CountryDetailCard countryDetailTitle={"Expected Fees"} countryDetail={countryInfo.expectedFees} />
                        </div>
                        <div className={`${baseClass}__detail_section`}>
                            <CountryDetailCard countryDetailTitle={"Investment Opportunities"} countryDetail={countryInfo.investmentOpportunities} />
                            <CountryDetailCard countryDetailTitle={"Acolin Scope of Services"} countryDetail={countryInfo.acolinScopeOfServices} />
                        </div>
                    </>
                ) : (
                    <Card>
                        <CardContent>
                            <ResponsiveTable>
                                <Grid
                                    className={`${baseClass}__dps`}
                                    data={orderBy(dps, sort)}
                                    sortable={true}
                                    sort={sort}
                                    onSortChange={(e: GridSortChangeEvent): void => {
                                        setSort(e.sort);
                                    }}
                                    filterable={false}
                                    scrollable="none"
                                >
                                    {columns.map(({ field, ...other }) => (
                                        <GridColumn key={field} field={field} {...other} />
                                    ))}
                                </Grid>
                            </ResponsiveTable>
                        </CardContent>
                    </Card>
                )}

                <div className={`${baseClass}__actions`}>
                    <Button themeColor="primary" onClick={onCloseDialog}>
                        Close
                    </Button>
                </div>
            </>
        </Dialog>
    );
};

export default CountryDetails;
