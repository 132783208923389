export type SubDistributionPartner = {
    accountid: string;
    omniId: number;
    acl_companyid: number;
    statecode: number;
    statuscode: number;
    address: string;
    city: string;
    country: string;
    name: string;
    category: string;
};
export interface IDataset {
    city: string;
    country: string;
    countryCode: string;
    id: number;
    name: string;
    type: string;
    isActive: boolean;
    isContracted: boolean;
    isGlobal: boolean;
    category: string;
    subDistributionPartners: SubDistributionPartner[];
}

export type dataCRM = {
    address1_telephone1: string;
    fullname: string;
    internalemailaddress: string;
};

export type Dp = {
    id: number;
    name: string;
    type: string;
    parentId?: number;
    isGlobal: boolean;
    treeListExpanded?: boolean;
    lastDDDate: string;
    risk: number;
    countryCode: string;
    startDate: string;
    isActive: boolean;
    isContracted: boolean;
    activeDate: string;
    accountid: string;
    omniId: string;
    acl_companyid: number;
    statecode: number;
    statuscode: number;
    address: string;
    city: string;
    country: string;
    continent?: string;
    description: string;
    website: string;
    fundDocumentSource: string;
    fundStaticDataSource: string;
    fundNAVDataSource: string;
    accountcategorycode: number;
    category: string;
    acl_liquidityrequirement: number;
    liquidityRequirements: string;
    acl_trackrecordrequirements: number;
    trackRecordRequirements: string;
    acl_investmentssrategies: string[];
    investmentStrategies: string[];
    acolinNotes: string;
    responsibleCRM: dataCRM;
    subDistributionPartners: SubDistributionPartner[];
};

export type ITransformedDp = {
    id?: number;
    country: string;
    continent?: string;
    dpsCount: number;
    connectedDpsCount: number;
    subDpsCount: number;
    connectedSubDpsCount: number;
    dpCoverage: number | string;
    subDpCoverage: number | string;
    itemsExpanded?: boolean;
    status: string;
    // DPs
    items: Array<Dp>;
};

export type ITransformedDps = Array<ITransformedDp>;

export enum Status {
    Contracted = "Contracted",
    PartlyContracted = "Partly Contracted",
    Available = "Available",
}

export enum StatusClass {
    Active = "active",
    Contracted = "active-all",
    PartlyContracted = "active-some",
    Available = "inactive",
}

export type ComplianceDetails = {
    omniId: string;
    risk: number;
    lastDDDate: string;
};

export type CountryInfo = {
    isoa2code: string;
    name: string;
    investmentOpportunities?: string;
    countryFacts?: string;
    investorsAndDistributors?: string;
    registration?: string;
    acolinScopeOfServices?: string;
    expectedFees?: string;
};
