import React from "react";

import { useAppUserContext } from "@src/common/Context";
import Logo from "@components/common/Logo";
import UserInfo from "@components/common/UserInfo";

import "./Header.scss";

const baseClass = "acl-header";

const Header: React.FC<{}> = () => {
    const [appUser, _setAppUser] = useAppUserContext();
    const { firstName, lastName, companyName } = appUser;

    return (
        <header className={`${baseClass}`}>
            <Logo />
            <UserInfo name={`${firstName ? firstName + " " : ""} ${lastName ? lastName : ""}`} company={companyName} />
        </header>
    );
};

export default Header;
