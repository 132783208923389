import React from "react";
import classNames from "classnames";

import "./Flag.scss";

const baseClass = "acl-flag";

type FlagSizes = "small" | "normal" | "large";

type FlagShapes = "square" | "rectangle" | "circle";

type IconSpacing = "left" | "right" | "both";

interface IProps {
    country: string;
    size?: FlagSizes;
    title?: string;
    spacing?: IconSpacing;
    shape?: FlagShapes;
    className?: string;
}

const Flag: React.FC<IProps> = props => {
    const { country, size, title, spacing, shape, className } = props;

    const classes = classNames(
        baseClass,
        country && `acl-flag--${country.toLowerCase()}`,
        size && `${baseClass}--${size}`,
        spacing && `${baseClass}--${spacing}`,
        shape && `acl-flag--${shape}`,
        className,
    );

    return <i data-testid="flag" title={title} role="img" aria-hidden="true" className={classes} />;
};

export default Flag;
