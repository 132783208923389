import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./InitiateOnboarding.scss";
import Page, { Title } from "@components/containers/Page";
import Navigation from "@components/common/Navigation";
import { OnboardingTab } from "@src/common/types";
import { StepContacts, StepDistribution, StepFunds, StepConfirmation, StepPayingAgent } from "./Steps";
import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { Fund, FundFile } from "../types";
import { BackToTop, LoadingPanel } from "@components/common";
import { DistribitionForms, InitialForms, StepAgentForm } from "./Steps/types";
import { initialForms, initialDistributionFields, initialStepAgentFields } from "./getInitialForms";
import { Button } from "@progress/kendo-react-buttons";
import axios from "@src/common/http";
import { API_ENDPOINT } from "@src/common/config";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "@components/common/ConfirmationDialog/ConfirmationDialog";

const baseClass = "acl-page-initiate-onboarding";

type OnboardingData = {
    contacts: InitialForms;
    payingAgent: StepAgentForm;
    distribution: DistribitionForms;
    funds: Fund[];
};

const getFileWithBuffer = (file: FundFile): { name: string; size: number; buffer: File } => {
    const {
        uploadFileInfo: { getRawFile },
        name,
        size,
        ...rest
    } = file;
    return { name, size, buffer: getRawFile() };
};

const getFileListFromFund = (fund: Fund): FundFile[] => {
    const { id: fundId, children, details } = fund;
    const { files } = details;
    const fundFiles = Object.entries(files).reduce((acc: FundFile[], [field, files]) => {
        const mappedFundFiles =
            files?.map((file: FundFile): FundFile => {
                if (!file.externalId) {
                    const temp = { fundId: fundId, field, docType: file.docType, ...getFileWithBuffer(file) };
                    return temp;
                } else {
                    return file;
                }
            }) ?? [];
        return [...acc, ...mappedFundFiles];
    }, []);
    const childrenFiles = children?.flatMap(child => getFileListFromFund(child)) ?? [];
    return [...fundFiles, ...childrenFiles];
};

const getFileListFromFunds = (funds: Fund[]) => {
    const files = funds.flatMap(getFileListFromFund);
    return files ?? [];
};

type ShowModal = {
    showCancel: boolean;
    showSubmit: boolean;
};

const initialShowModal: ShowModal = {
    showCancel: false,
    showSubmit: false,
};

const uploadFiles = async (rlsWizardId: string, files: FundFile[]): Promise<void> => {
    const filesToUpload = files.map(file => {
        const { buffer, field, fundId, name, size, docType } = file;
        const formData = new FormData();
        formData.append("buffer", buffer);
        formData.append("field", field);
        formData.append("name", name);
        formData.append("fundId", fundId);
        formData.append("docType", docType);
        formData.append("size", size?.toString() ?? "");
        return formData;
    });

    if (filesToUpload.length > 0) {
        await Promise.all(
            filesToUpload.map(formData =>
                axios.patch(`${API_ENDPOINT}/rls-wizard/${rlsWizardId}/add-file`, formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                }),
            ),
        );
    }
};

const removeFiles = async (rlsWizardId: string, files: FundFile[]): Promise<void> => {
    if (files.length > 0) {
        await Promise.all(files.map(file => axios.patch(`${API_ENDPOINT}/rls-wizard/${rlsWizardId}/remove-file/${file._id}`)));
    }
};

const InitiateOnboarding: React.FC = () => {
    const [appUser] = useAppUserContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rlsWizardId, setRlsWizardID] = useState<string>("");
    const [selectedTab, setSelectedTab] = useState<OnboardingTab>("Funds");
    const [funds, setFunds] = useState<Fund[]>([]);
    const [oldFiles, setOldFiles] = useState<FundFile[]>([]);
    const navigate = useNavigate();

    const [stepDistribution, setStepDistribution] = useState<DistribitionForms>(initialDistributionFields);
    const [stepPayingAgent, setStepPayingAgent] = useState<StepAgentForm>(initialStepAgentFields);
    const [stepContacts, setStepContacts] = useState<InitialForms>(initialForms);
    const [showModal, setShowModal] = useState<ShowModal>(initialShowModal);
    const { sendNotification } = useNotificationContext();

    const onboardingData: OnboardingData = useMemo(
        () => ({
            contacts: stepContacts,
            payingAgent: stepPayingAgent,
            distribution: stepDistribution,
            funds,
        }),
        [stepContacts, stepPayingAgent, stepDistribution, funds],
    );

    const showCancelModal = useCallback(() => {
        setShowModal({ ...initialShowModal, showCancel: true });
    }, []);

    const showSubmitModal = useCallback(() => {
        setShowModal({ ...initialShowModal, showSubmit: true });
    }, []);

    const closeModals = useCallback((): void => {
        setShowModal(initialShowModal);
    }, []);

    const initForm = useCallback(async () => {
        const response = await axios.get(`${API_ENDPOINT}/rls-wizard`, { params: { isSubmit: false, isCancelled: false } });

        const { data: onboardingDataDB } = response;
        if (!onboardingDataDB) {
            return;
        }
        const { rlsWizard, files } = onboardingDataDB;
        setOldFiles(files);
        const { contacts, payingAgent, distribution, funds, _id: rlsWizardId } = rlsWizard;
        const mappedFiles = files.reduce((acc, file: FundFile) => {
            const { fundId, field } = file;
            if (!acc[fundId]) {
                acc[fundId] = {};
            }
            if (!acc[fundId][field]) {
                acc[fundId][field] = [];
            }
            acc[fundId][field].push(file);
            return acc;
        }, {});

        const extendFundWithFiles = fund => {
            const { id } = fund;
            fund.details.files = mappedFiles[id] ?? {};
            if (fund.children?.length > 0) {
                fund.children = fund.children.map(child => extendFundWithFiles(child));
            }
            return fund;
        };

        const fundsWithFiles = funds.map(extendFundWithFiles);

        setStepContacts({
            main: {
                fullName: contacts?.main?.fullName,
                phone: contacts?.main?.phone,
                email: contacts?.main?.email,
            },
            billing: {
                fullName: contacts?.billing?.fullName,
                phone: contacts?.billing?.phone,
                email: contacts?.billing?.email,
                ccemail: contacts?.billing?.ccemail,
            },
            legal: {
                fullName: contacts?.legal?.fullName,
                phone: contacts?.legal?.phone,
                email: contacts?.legal?.email,
            },
            compliance: {
                fullName: contacts?.compliance?.fullName,
                phone: contacts?.compliance?.phone,
                email: contacts?.compliance?.email,
            },
            data: {
                fullName: contacts?.data?.fullName,
                phone: contacts?.data?.phone,
                email: contacts?.data?.email,
            },
        });
        setStepPayingAgent({
            form: {
                payingAgentName: payingAgent?.form?.payingAgentName,
                person: payingAgent?.form?.person,
                phone: payingAgent?.form?.phone,
                email: payingAgent?.form?.email,
                address: payingAgent?.form?.address,
            },
            selected: payingAgent?.selected,
            selectedAgent: payingAgent?.selectedAgent,
        });
        setStepDistribution({
            form: {
                fullName: distribution?.form?.fullName,
                fullAddress: distribution?.form?.fullAddress,
                website: distribution?.form?.website,
            },
            fundProvider: distribution?.fundProvider,
            authorized: distribution?.authorized,
            distributed: distribution?.distributed,
        });
        setFunds(fundsWithFiles);
        setRlsWizardID(rlsWizardId);
    }, []);

    const createOnboarding = async ({ data }) => axios.post(`${API_ENDPOINT}/rls-wizard`, data);
    const updateOnboarding = async ({ data, id }) => axios.put(`${API_ENDPOINT}/rls-wizard/${id}`, data);
    const submitOnboarding = async (id: string) => axios.patch(`${API_ENDPOINT}/rls-wizard/${id}/submit`);

    const upsert = useCallback(async (): Promise<string> => {
        const { funds } = onboardingData;
        const files = getFileListFromFunds(funds);
        const filesToUpload = files.filter(file => !file.externalId);
        const filesToRemove = oldFiles.filter(oldFile => !files.find(file => file._id === oldFile._id));

        if (rlsWizardId) {
            await uploadFiles(rlsWizardId, filesToUpload);
            await removeFiles(rlsWizardId, filesToRemove);
            await updateOnboarding({ data: onboardingData, id: rlsWizardId });
            return rlsWizardId;
        } else {
            const { data } = await createOnboarding({ data: onboardingData });
            const rlsWizardId = data._id;
            await uploadFiles(rlsWizardId, filesToUpload);
            return rlsWizardId;
        }
    }, [onboardingData, rlsWizardId, oldFiles, appUser]);

    const submit = useCallback(async (): Promise<void> => {
        try {
            setIsLoading(true);
            const wizardId = await upsert();
            console.log("WIZARD ID: ", wizardId);
            await submitOnboarding(wizardId);
            // await initForm();
            navigate("/fund-representation-ch");
            sendNotification({
                timeout: 10000,
                type: "success",
                message: "Onboarding data submited",
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [upsert, initForm, rlsWizardId]);

    const save = useCallback(async (): Promise<void> => {
        try {
            setIsLoading(true);
            await upsert();
            sendNotification({
                timeout: 10000,
                type: "success",
                message: "Onboarding data saved",
            });
            await initForm();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [upsert, initForm]);

    const cancel = useCallback(async (): Promise<void> => {
        if (!rlsWizardId) {
            navigate("/fund-representation-ch");
            sendNotification({
                timeout: 10000,
                type: "success",
                message: "Onboarding data cancelled",
            });
            return;
        }
        setIsLoading(true);
        axios
            .patch(`${API_ENDPOINT}/rls-wizard/${rlsWizardId}/cancel`)
            .then(response => {
                const { status, data } = response;
                if (status === 200) {
                    navigate("/fund-representation-ch");
                    sendNotification({
                        timeout: 10000,
                        type: "success",
                        message: "Onboarding data cancelled",
                    });
                }
            })
            .catch(error => console.error(error))
            .finally(() => setIsLoading(false));
    }, [rlsWizardId]);

    const updateNewFunds = useCallback((data: Fund[]) => {
        setFunds(data);
    }, []);

    const routes = [
        { label: "Funds", isActive: selectedTab === "Funds" },
        { label: "Distribution", isActive: selectedTab === "Distribution" },
        { label: "Paying Agent", isActive: selectedTab === "Paying Agent" },
        { label: "Contacts", isActive: selectedTab === "Contacts" },
    ];

    const selectedRoute = (route: OnboardingTab): React.ReactNode => {
        switch (route) {
            case "Distribution":
                return <StepDistribution state={stepDistribution} setState={setStepDistribution} />;
            case "Paying Agent":
                return <StepPayingAgent state={stepPayingAgent} setState={setStepPayingAgent} />;
            case "Contacts":
                return <StepContacts state={stepContacts} setState={setStepContacts} />;
            case "Confirmation":
                return <StepConfirmation />;
            case "Funds":
            default:
                return <StepFunds funds={funds} setFunds={updateNewFunds} />;
        }
    };

    useEffect(() => {
        setIsLoading(true);
        initForm()
            .catch(error => console.error("Error fetching data:", error))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        const handleClickOutside = (event): void => {
            if (event.target.classList.contains("k-overlay")) {
                setShowModal(initialShowModal);
            }
        };

        const { showCancel, showSubmit } = showModal;

        if (showCancel || showSubmit) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return (): void => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showModal]);

    return isLoading ? (
        <LoadingPanel />
    ) : (
        <Page>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>Fund Representation Switzerland</Title>
            </header>
            <div className={`${baseClass}__initialOnboardingMenu`}>
                <Navigation routes={routes} onClick={(route: OnboardingTab): void => setSelectedTab(route)} />
                <div className={`${baseClass}__initialOnboardingButtons`}>
                    <Button themeColor="primary" onClick={showCancelModal}>
                        Cancel
                    </Button>

                    <Button themeColor="primary" onClick={save} className={`${baseClass}__initialOnboardingButton`}>
                        Save
                    </Button>
                    <Button themeColor="primary" onClick={showSubmitModal} className={`${baseClass}__initialOnboardingButton`}>
                        Submit
                    </Button>
                </div>
            </div>
            {selectedRoute(selectedTab)}
            <ConfirmationDialog shouldOpen={showModal.showCancel} onClose={closeModals} onConfirm={cancel}>
                <div className={`${baseClass}__success-message`}>
                    <p>Are you sure that you want to cancel this?</p>
                </div>
            </ConfirmationDialog>
            <ConfirmationDialog
                shouldOpen={showModal.showSubmit}
                onClose={closeModals}
                onConfirm={() => {
                    submit();
                    closeModals();
                }}
                closeButtonText="No"
                confirmButtonText="Yes"
            >
                <div className={`${baseClass}__success-message`}>
                    <div className={`${baseClass}__success-message`}>
                        <p>Confirm Fund onboarding submission.</p>
                        <p>Our Funds Regulation Team will review the submitted information and revert to you via email.</p>
                        <p>
                            Should you have any questions, feel free to reach out to us at{" "}
                            <a href="mailto:fundsregulationsch@acolin.com">fundsregulationsch@acolin.com.</a>
                        </p>
                    </div>
                    <div className={`${baseClass}__confirmMessage`}>
                        <p>Are you sure that you want to confirm?</p>
                    </div>
                </div>
            </ConfirmationDialog>
            <BackToTop />
        </Page>
    );
};

export default InitiateOnboarding;
