import { ApolloClient, createHttpLink, from, InMemoryCache, ServerError } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { publish } from "pubsub-js";
import { PSChannel } from "@src/common/types";
import { logout } from "@src/common/token";
import { API_ENDPOINT } from "@src/common/config";

const httpLink = createHttpLink({
    uri: `${API_ENDPOINT}/graphql`,
    // uri: `${API_ENDPOINT}/portal/graphql`,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("authToken");
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    const serverError = networkError as ServerError;
    const status = serverError?.statusCode;
    const response = serverError?.response;

    if (status === 401) {
        logout();
    } else if (status === 400 || status > 401) {
        publish(PSChannel.Error, {
            data: {
                config: {
                    baseURL: response?.url,
                },
                data: {
                    error: {
                        name: serverError?.name,
                        message: serverError?.message,
                    },
                },
            },
        });
    } else if (graphQLErrors) {
        for (const gqlError of graphQLErrors) {
            publish(PSChannel.Error, {
                data: {
                    config: {
                        baseURL: response?.url,
                    },
                    data: {
                        error: {
                            name: gqlError.path,
                            message: gqlError.message,
                        },
                    },
                },
            });
        }
    }
});

/*
 * errorPolicy shoud stay default ("none") and be overriden in queries, if needed,
 * for onCompleted callback to work properly (to be executed only when there are zero errors)
 *
 * https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies
 *
 * typePolicies was specified for XLSXData response type, because data for that resource
 * is cached in a bad way, resulting in an array of the same first element (that was cached).
 * Caching is done based on response type and id (_id) field, and in this example server returns
 * and array of elements that have same ids, which resulted in a "bug".
 *
 * https://www.apollographql.com/docs/react/caching/overview/#2-generate-cache-ids
 *
 */
export const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            XLSXData: {
                keyFields: ["isin", "nav", "validFrom", "validTo"],
            },
        },
    }),
    link: from([authLink, errorLink, httpLink]),
    defaultOptions: {
        watchQuery: {
            errorPolicy: "none",
            fetchPolicy: "cache-and-network",
        },
        query: {
            errorPolicy: "none",
            fetchPolicy: "no-cache",
        },
        mutate: {
            errorPolicy: "none",
            fetchPolicy: "no-cache",
        },
    },
});

export const clearGQLCache = async (): Promise<void> => {
    await client.clearStore();
};
