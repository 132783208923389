import React from "react";
import { Link } from "react-router-dom";

import logoUrl from "@images/acolin-logo-no-strapline.svg";

import "./Logo.scss";

const baseClass = "acl-logo";

interface IProps {
    isAuth?: boolean;
}

const Logo: React.FC<IProps> = ({ isAuth = false }) => (
    <figure className={`${baseClass}`}>
        {isAuth ? (
            <div className={`${baseClass}__link`} title="Acolin Client Portal">
                <img className={`${baseClass}__image`} src={logoUrl} alt="Acolin" />
                <figcaption className={`${baseClass}__caption`}>Client Portal</figcaption>
            </div>
        ) : (
            <Link className={`${baseClass}__link`} to="/home" title="Acolin Client Portal">
                <img className={`${baseClass}__image`} src={logoUrl} alt="Acolin" />
                <figcaption className={`${baseClass}__caption`}>Client Portal</figcaption>
            </Link>
        )}
    </figure>
);

export default React.memo(Logo);
