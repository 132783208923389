import React from "react";
import classNames from "classnames";

import { NumericTextBox } from "@progress/kendo-react-inputs";

import "./RangeFilterCell.scss";

const baseClass = "acl-page-range-filter-cell";

type Value = {
    min: number;
    max: number;
};

interface IProps {
    value?: Value;
    isCompact?: boolean;
    onChange?({ value, operator, syntheticEvent }): void;
}

const RangeFilterCell: React.FC<IProps> = props => {
    let minTextBox;
    let maxTextBox;

    const inRange = (current, { min, max }): boolean => (min === null || current >= min) && (max === null || current <= max);

    const onChange = (event): void => {
        props.onChange({
            value: {
                min: minTextBox.value,
                max: maxTextBox.value,
            },
            operator: inRange,
            syntheticEvent: event.syntheticEvent,
        });
    };

    const value = props.value || null;
    return (
        <div className={classNames(`${baseClass}`, `${baseClass}__compact-view`)} data-testid="range-filter-cell">
            <NumericTextBox
                className={`${baseClass}__input`}
                placeholder="Min"
                value={value && value.min}
                ref={(numeric): void => {
                    minTextBox = numeric;
                }}
                onChange={onChange}
            />
            <NumericTextBox
                className={`${baseClass}__input`}
                placeholder="Max"
                value={value && value.max}
                ref={(numeric): void => {
                    maxTextBox = numeric;
                }}
                onChange={onChange}
            />
        </div>
    );
};

export default RangeFilterCell;
