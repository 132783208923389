import React, { useState, Dispatch, SetStateAction, useContext, useMemo, useEffect, ReactNode } from "react";
import { IAppUser, fallbackUser } from "@src/common/types";

type ContextValue = [IAppUser, Dispatch<SetStateAction<IAppUser>>];
const initialValue: ContextValue = [fallbackUser, (): void => {}];

export const AppUserContext = React.createContext<ContextValue>(initialValue);
AppUserContext.displayName = "AppUserContext";

const getInitialState = (): IAppUser => JSON.parse(localStorage.getItem("appUser")) || fallbackUser;

interface Props {
    children?: ReactNode;
}

export const AppUserContextProvider: React.FC<Props> = ({ children }) => {
    const [appUser, setAppUser] = useState<IAppUser>(getInitialState);

    useEffect(() => {
        localStorage.setItem("appUser", JSON.stringify(appUser));
    }, [appUser]);

    const contextValue = useMemo<ContextValue>(() => [appUser, setAppUser], [appUser]);

    return <AppUserContext.Provider value={contextValue}>{children}</AppUserContext.Provider>;
};

export const useAppUserContext = (): ContextValue => useContext(AppUserContext);
