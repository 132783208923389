import React, { ReactNode } from "react";
import { useLocation, matchPath } from "react-router-dom";

import routes, { AppRoute } from "@src/common/routes";
import AuthLayout from "@components/containers/AuthLayout";
import PageLayout from "@components/containers/PageLayout";
import ErrorLayout from "@components/containers/ErrorLayout";

const checkRoute = (path: string, routes: AppRoute[]): boolean => routes.some(route => !!matchPath({ path: route.path }, path));

interface Props {
    children?: ReactNode;
}

const AppLayout: React.FC<Props> = ({ children }) => {
    const { pathname } = useLocation();
    const isAuth = checkRoute(pathname, routes.authentication);
    const isPage = checkRoute(pathname, routes.pages());

    // prettier-ignore
    return isAuth
        ? <AuthLayout>{children}</AuthLayout>
        : isPage
            ? <PageLayout>{children}</PageLayout>
            : <ErrorLayout>{children}</ErrorLayout>;
};

export default AppLayout;
