import React, { useState } from "react";
import Page, { Title } from "@components/containers/Page";
import NotificationsList from "@components/common/Notifications";

import "./ReleaseNotes.scss";
import { useQuery } from "@apollo/client";
import { GET_RELEASE_NOTES } from "@src/common/graphql";

const baseClass = "acl-page-releasenotes";

const ReleaseNotes: React.FC<{}> = () => {
    const [releaseNotes, setReleaseNotes] = useState([]);

    const { loading, error, data } = useQuery(GET_RELEASE_NOTES, {
        fetchPolicy: "cache-first",
        onCompleted: data => {
            setReleaseNotes(
                data?.releaseNotes.map(releaseNote => {
                    const { id, subject, message, overview, releaseDate: date, attachmentId, attachmentName } = releaseNote;
                    return { id, subject, message, overview, date, attachmentId, attachmentName };
                }),
            );
        },
    });

    return (
        <Page className={baseClass}>
            <Title>Release Notes</Title>
            <NotificationsList loading={loading} notifications={releaseNotes} className={`acl-widget ${baseClass}__messages`} pageable={true} />
        </Page>
    );
};

export default ReleaseNotes;
