import React, { useState, useEffect, useCallback } from "react";
import { publish } from "pubsub-js";
import { useLazyQuery } from "@apollo/client";
import Mark from "mark.js";

import { groupBy } from "@progress/kendo-data-query";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn, GridCellProps, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { RangeSlider, SliderLabel } from "@progress/kendo-react-inputs";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { useAppUserContext } from "@src/common/Context";
import Page, { Title } from "@components/containers/Page";
import { Card, CardHeader, CardContent, BackToTop, LoadingPanel, Flag, DateRangeFilterCell, ResponsiveTable } from "@components/common";

import { IDataset, ITransformedDps, RiskScoreFilter } from "./types";
import "./ComplianceDashboard.scss";
import { format } from "date-fns";
import classNames from "classnames";
import { getRiskPercentageColor } from "@src/common/util";
import DistributorDetails from "../DistributionNetwork/DistributorDetails";
import { Dp } from "../DistributionNetwork/types";
import { GET_DISTRIBUTION_PARTNERS } from "@src/common/graphql";
import { errors } from "@src/common/errors";
import { PSChannel } from "@src/common/types";

const baseClass = "acl-page-compliance";

const transform = (dataset: IDataset[]): ITransformedDps => {
    // Group dataset by Country code
    const byCountry = groupBy(dataset, [{ field: "country" }]).map((item, index) => ({
        id: index + 1,
        country: item.value,
        countryCode: item.items[0]?.countryCode,
        items: item.items.map(item => ({ ...item, isGlobal: item.type === "DPG" })),
    }));

    return orderBy(byCountry, [
        { field: "items.length", dir: "desc" },
        { field: "country", dir: "asc" },
    ]);
};

const getCountries = (dps: ITransformedDps): Array<string> => {
    const countries = [];
    dps.forEach(dp => countries.push(dp.country));
    return countries;
};

const initialSort: Array<SortDescriptor> = [{ field: "name", dir: "asc" }];
const initialRiskScoreFilter = {
    start: 0,
    end: 100,
};

const initialDDDateRange = {
    min: null,
    max: null,
};

const ComplianceDashboard: React.FC<{}> = () => {
    const [appUser, _setAppUser] = useAppUserContext();
    const [dps, setDps] = useState<ITransformedDps>([]);
    const [filteredDps, setFilteredDps] = useState<ITransformedDps>([]);
    const [sort, setSort] = useState(initialSort);
    const [nameFilter, setNameFilter] = useState<string>("");
    const [cityFilter, setCityFilter] = useState<string>("");
    const [riskScoreFilter, setRiskScoreFilter] = useState<RiskScoreFilter>(initialRiskScoreFilter);
    const [countriesList, setCountriesList] = React.useState([]);
    const [categoryList, setCategoryList] = React.useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [lastDDDateRange, setLastDDDateRange] = useState(initialDDDateRange);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedDistributor, setSelectedDistributor] = useState<Dp>();

    const openDistributorDetails = (dp): void => {
        setSelectedDistributor(dp);
        setShowModal(true);
    };

    const CustomCell: React.FC<GridCellProps> = ({ dataItem, field }) => <td className={`${baseClass}__contracted`}>{dataItem[field] || "N/A"}</td>;

    const RiskCell: React.FC<GridCellProps> = ({ dataItem, field }) => {
        const percentage = Number(dataItem[field]);
        return (
            <td className={`${baseClass}__contracted`} style={{ color: getRiskPercentageColor(percentage) }}>
                {Number.isNaN(percentage) ? "Pending" : `${percentage}%`}
            </td>
        );
    };

    const CityCell: React.FC<GridCellProps> = ({ dataItem }) => (
        <td className={classNames("city-filter", dataItem.isContracted ? `${baseClass}__contracted` : null)}>{dataItem.city}</td>
    );

    const NameCell: React.FC<GridCellProps> = ({ dataItem }) => (
        <td className={classNames("name-filter", dataItem.isContracted ? `${baseClass}__contracted` : null)}>{dataItem.name}</td>
    );

    const DateCell: React.FC<GridCellProps> = ({ dataItem, field }) => (
        <td className={`${baseClass}__contracted`}>{dataItem[field] ? format(new Date(dataItem[field]), "yyyy-MM-dd") : "Pending"}</td>
    );

    const columns = [
        { field: "name", title: "Distribution Partner", cell: NameCell },
        { field: "city", title: "City", cell: CityCell, width: 200 },
        { field: "category", title: "Category", cell: CustomCell, width: 250 },
        { field: "risk", title: "Risk Score", cell: RiskCell, width: 100 },
        { field: "lastDDDate", title: "Last Due Diligence", cell: DateCell, width: 150 },
    ];

    const filterDocs = (): void => {
        const countriesFilter = selectedCountries.length ? selectedCountries : countriesList;

        const filtered = dps.reduce((acc, dp) => {
            if (!countriesFilter.includes(dp.country)) {
                return acc;
            }
            const { items } = dp;
            const filteredItems = items.filter(item => {
                const { name, city, risk, category, frequency, lastDDDate: ddDate } = item;
                const lastDDDate = new Date(ddDate);
                const byName = !nameFilter ? true : name?.toLowerCase().includes(nameFilter.toLowerCase());
                const byCity = !cityFilter ? true : city?.toLowerCase().includes(cityFilter.toLowerCase());
                const byRisk =
                    riskScoreFilter.start === 0 && riskScoreFilter.end === 100
                        ? true
                        : risk !== null && riskScoreFilter.start <= risk && risk <= riskScoreFilter.end;
                const byCategory = selectedCategories.length === 0 ? true : selectedCategories.includes(category);

                const { min: minLastDDDate, max: maxLastDDDate } = lastDDDateRange;
                const greaterThenMinLastDDDate = minLastDDDate === null ? true : lastDDDate.getTime() >= minLastDDDate.getTime();
                const lessThenMaxLastDDDate = maxLastDDDate === null ? true : lastDDDate.getTime() <= maxLastDDDate.getTime();
                const byLastDDDate = greaterThenMinLastDDDate && lessThenMaxLastDDDate;

                return byName && byCity && byRisk && byCategory && byLastDDDate;
            });

            if (filteredItems.length > 0) {
                acc.push({
                    ...dp,
                    itemsExpanded:
                        nameFilter || cityFilter || lastDDDateRange.max || lastDDDateRange.min || selectedCategories.length || selectedCountries.length,
                    items: filteredItems,
                });
            }

            return acc;
        }, []);

        setFilteredDps(filtered);
    };

    const [getDistributionPartners, { loading, error, data }] = useLazyQuery(GET_DISTRIBUTION_PARTNERS, {
        variables: { companyId: appUser.companyId },
        fetchPolicy: "cache-first",
        onCompleted: data => {
            const complianceDetailsMap =
                data.allComplianceDetails?.reduce((acc: Record<string, { risk: number; lastDDDate: string }>, details) => {
                    const { omniId, risk, lastDDDate } = details;
                    if (omniId) {
                        acc[omniId.toString()] = { risk, lastDDDate };
                    }
                    return acc;
                }, {}) ?? {};

            const dps =
                data?.distributionPartners
                    .filter(dp => dp.country && dp.isContracted)
                    .map((dp: Dp) => {
                        const { omniId } = dp;
                        return {
                            ...dp,
                            risk: complianceDetailsMap[omniId]?.risk,
                            lastDDDate: complianceDetailsMap[omniId]?.lastDDDate,
                        };
                    }) ?? [];
            const categories = [...new Set(dps.map(obj => obj.category))].filter(obj => obj).sort();
            setCategoryList(categories);
            const transformedDPs = transform(dps);
            setCountriesList(getCountries(transformedDPs).sort());
            setDps(transformedDPs);
        },
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }
        getDistributionPartners().catch(error => console.error(error));
    }, []);

    useEffect(() => {
        const handleClickOutside = (event): void => {
            if (event.target.classList.contains("k-overlay")) {
                setShowModal(false);
            }
        };

        if (showModal) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return (): void => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showModal]);

    useEffect(() => {
        if (dps) {
            filterDocs();
        }
        setTimeout(() => {
            // Highlight searched name
            const markName = new Mark(document.querySelectorAll(".name-filter"));
            markName.unmark({
                done: () => markName.mark(nameFilter, { className: `${baseClass}__highlight` }),
            });
            // Highlight searched city
            const markCity = new Mark(document.querySelectorAll(".city-filter"));
            markCity.unmark({
                done: () => markCity.mark(cityFilter, { className: `${baseClass}__highlight` }),
            });
        });
    }, [dps, selectedCountries, nameFilter, cityFilter, riskScoreFilter, selectedCategories, lastDDDateRange]);

    const clearFilters = useCallback(() => {
        setSelectedCountries([]);
        setSelectedCategories([]);
        setCityFilter("");
        setNameFilter("");
        setRiskScoreFilter(initialRiskScoreFilter);
        setLastDDDateRange(initialDDDateRange);
    }, []);

    return (
        <Page className={baseClass}>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>Compliance Dashboard</Title>
            </header>
            <div className={`${baseClass}__main`}>
                {loading ? (
                    <Card className={`${baseClass}__list`}>
                        <div className={`${baseClass}__loading-wrapper`}>
                            <LoadingPanel />
                        </div>
                    </Card>
                ) : (
                    <Card className={`${baseClass}__list`}>
                        <CardHeader className={`${baseClass}__main__header`}>
                            <h3 className={`${baseClass}__main__title`}>Compliance overview of your distribution network within Acolin's DNM</h3>
                        </CardHeader>
                        <CardContent>
                            <div className={`${baseClass}__filters`}>
                                <div className={`${baseClass}__groupOfFilters`}>
                                    <div className={`${baseClass}__filter`}>
                                        <Label>Distributor</Label>
                                        <Input
                                            type="text"
                                            value={nameFilter}
                                            placeholder="Enter name"
                                            className={`${baseClass}__input-medium`}
                                            onChange={(event): void => setNameFilter(event.value)}
                                        />
                                    </div>
                                    <div className={`${baseClass}__filter`}>
                                        <Label>Country</Label>
                                        <MultiSelect
                                            className={`${baseClass}__input-medium`}
                                            data={countriesList}
                                            value={selectedCountries}
                                            placeholder="Select countries"
                                            autoClose={false}
                                            onChange={(event: MultiSelectChangeEvent): void => setSelectedCountries(event.value)}
                                            tags={
                                                selectedCountries.length > 0
                                                    ? [{ text: `${selectedCountries.length} selected`, data: [...selectedCountries] }]
                                                    : []
                                            }
                                        />
                                    </div>
                                    <div className={`${baseClass}__filter`}>
                                        <Label>City</Label>
                                        <Input
                                            type="text"
                                            value={cityFilter}
                                            placeholder="Enter city"
                                            className={`${baseClass}__input-small`}
                                            onChange={(event): void => setCityFilter(event.value)}
                                        />
                                    </div>
                                </div>
                                <div className={`${baseClass}__groupOfFilters`}>
                                    <div className={`${baseClass}__filter`}>
                                        <Label>Category</Label>
                                        <MultiSelect
                                            className={`${baseClass}__input-medium`}
                                            data={categoryList}
                                            value={selectedCategories}
                                            placeholder="Select categories"
                                            autoClose={false}
                                            onChange={(event: MultiSelectChangeEvent): void => setSelectedCategories(event.value)}
                                            tags={
                                                selectedCategories.length > 0
                                                    ? [{ text: `${selectedCategories.length} selected`, data: [...selectedCategories] }]
                                                    : []
                                            }
                                        />
                                    </div>
                                    <div className={`${baseClass}__filter`}>
                                        <Label>Risk Score</Label>
                                        <RangeSlider
                                            defaultValue={initialRiskScoreFilter}
                                            value={riskScoreFilter}
                                            step={1}
                                            min={0}
                                            max={100}
                                            className={`${baseClass}__slider`}
                                            onChange={(event): void => setRiskScoreFilter(event.value)}
                                        >
                                            {[0, 25, 50, 75, 100].map((perc, i) => (
                                                <SliderLabel key={i} position={perc}>
                                                    {perc.toString()}
                                                </SliderLabel>
                                            ))}
                                        </RangeSlider>
                                    </div>
                                    <div className={classNames(`${baseClass}__filter`, `${baseClass}__input-small`)}>
                                        <Label>Last Due Diligence</Label>
                                        <DateRangeFilterCell
                                            min={lastDDDateRange.min}
                                            max={lastDDDateRange.max}
                                            isCompact={true}
                                            onDateFilterChange={(e): void => {
                                                const { operator, value } = e;
                                                const key = operator === "gte" ? "min" : "max";
                                                setLastDDDateRange(prevState => ({ ...prevState, [key]: value }));
                                            }}
                                        />
                                    </div>
                                    <button className="k-button k-button-icon k-clear-button-visible" title="Clear" onClick={clearFilters}>
                                        <span className="k-icon k-font-icon k-i-filter-clear" />
                                    </button>
                                </div>
                            </div>

                            {filteredDps?.length ? (
                                filteredDps.map(item => (
                                    <ResponsiveTable key={item.id}>
                                        <details className={`${baseClass}__details`} open={item.itemsExpanded}>
                                            <summary className={`${baseClass}__summary`}>
                                                <h3>
                                                    {item.countryCode && <Flag country={item.countryCode} spacing="right" shape="circle" />}
                                                    {`${item.country} (${item.items.length})`}
                                                </h3>
                                            </summary>
                                            <Grid
                                                scrollable="none"
                                                data={orderBy(item.items, sort)}
                                                sortable={true}
                                                sort={sort}
                                                onRowClick={(e): void => {
                                                    openDistributorDetails(e.dataItem);
                                                }}
                                                onSortChange={(e: GridSortChangeEvent): void => {
                                                    setSort(e.sort);
                                                }}
                                            >
                                                {columns.map(({ field, ...other }) => (
                                                    <GridColumn key={field} field={field} {...other} />
                                                ))}
                                            </Grid>
                                        </details>
                                    </ResponsiveTable>
                                ))
                            ) : (
                                <div className={`${baseClass}__no-results`}>No results found for selected filter</div>
                            )}
                        </CardContent>
                    </Card>
                )}
            </div>
            {showModal && <DistributorDetails dp={selectedDistributor} onClose={(): void => setShowModal(false)} />}
            <BackToTop />
        </Page>
    );
};

export default ComplianceDashboard;
