import React from "react";

import { Input } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";
import { Tooltip } from "react-tooltip";
import { FieldWrapper, FieldRenderProps } from "@progress/kendo-react-form";

import Icon from "@components/common/Icon";

import "./LabelInput.scss";

const baseClass = "acl-label-input";

const LabelInput: React.FC<{}> = (fieldRenderProps: FieldRenderProps) => {
    const { type, validationMessage, visited, label, id, valid, disabled, ariaDescribedBy, tooltip, ...others } = fieldRenderProps;
    const showValidationMessage = visited && validationMessage;

    return (
        <FieldWrapper>
            <Label editorId={id} editorValid={valid}>
                {label}
                {tooltip && (
                    <div>
                        <a data-tooltip-id="tooltipIcon" data-tooltip-content={tooltip}>
                            <Icon className={`${baseClass}__tooltip`} name="question-circle" />
                        </a>
                        <Tooltip
                            id="tooltipIcon"
                            place="right"
                            delayShow={100}
                            style={{
                                zIndex: 999,
                                whiteSpace: "normal",
                                maxWidth: "100%",
                            }}
                        />
                    </div>
                )}
            </Label>
            <Input type={type} id={id} valid={valid} disabled={disabled} ariaDescribedBy={ariaDescribedBy} {...others} />
            {showValidationMessage && <Error id={ariaDescribedBy}>{validationMessage}</Error>}
        </FieldWrapper>
    );
};

export default LabelInput;
