import React from "react";
import { useLocation } from "react-router-dom";

import { useUIContext, useAppUserContext } from "@src/common/Context";
import { isURL } from "@src/common/util";
import Link from "@components/common/Link";

import "./MenuList.scss";

const baseClass = "acl-menu-list";

export type TMenuItem = {
    title: string;
    path: string;
    isHidden?: boolean;
    isExternal?: boolean;
    isNew?: boolean;
    isBeta?: boolean;
};

interface IProps {
    headline: string;
    list: Array<TMenuItem>;
}

const MenuList: React.FC<IProps> = ({ headline, list }) => {
    const location = useLocation();
    const [appUser, _setAppUser] = useAppUserContext();
    const [context, setContext] = useUIContext();

    const handleClick = (): void => setContext({ ...context, isSidebarOpen: false });

    return (
        <dl className={`${baseClass}`}>
            <dt className={`${baseClass}__title`}>{headline}</dt>
            {list.map(item => {
                const isExternal = typeof item.path === "string" && isURL(item.path);

                return (
                    !item.isHidden && (
                        <dd className={`${baseClass}__item`} key={item.path}>
                            <Link
                                to={item.path}
                                type="text"
                                target={isExternal ? "_blank" : null}
                                active={location.pathname === item.path || (location.pathname.includes("share-classes") && item.path === "/funds")}
                                onClick={handleClick}
                            >
                                {item.title}
                            </Link>
                            {item.isNew && <sup className={`${baseClass}__new`}>NEW</sup>}
                            {item.isBeta && <sup className={`${baseClass}__new`}>BETA</sup>}
                        </dd>
                    )
                );
            })}
        </dl>
    );
};

export default MenuList;
