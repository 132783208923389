import React, { useState, useEffect } from "react";
import classNames from "classnames";

import Icon from "@components/common/Icon";
import { toTop } from "@src/common/util";

import "./BackToTop.scss";

const baseClass = "acl-back-to-top";

const BackToTop: React.FC = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        function scrollToTop(): void {
            setShow(window.scrollY > 200);
        }

        window.addEventListener("scroll", scrollToTop);

        return (): void => {
            window.removeEventListener("scroll", scrollToTop);
        };
    }, [show]);

    const classes: string = classNames(baseClass, show && `${baseClass}--show`);

    return (
        <div onClick={toTop} className={classes}>
            <Icon name="arrow-up-1" className={`${baseClass}__icon`} />
        </div>
    );
};

export default BackToTop;
