import React from "react";
import classNames from "classnames";

import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";

import "./FilterInput.scss";

const baseClass = "acl-filter-input";

type Props = {
    placeholder: string;
    style?: object;
    className?: string;
    onChange?(filter: string | number | string[]): void;
};

const FilterInput: React.FC<Props> = ({ placeholder, onChange = (): void => {}, className, style }) => {
    const classes: string = classNames(baseClass, className);
    const handleChange = (event: InputChangeEvent): void => onChange(event.target.value);

    return <Input placeholder={placeholder} className={classes} style={style} onChange={handleChange} />;
};

export default FilterInput;
