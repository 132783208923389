import React, { useEffect, useState } from "react";

import { useUIContext } from "@src/common/Context";
import Hamburger from "@components/common/Hamburger";
import MenuList, { TMenuItem } from "@components/common/MenuList";
import Footer from "@components/common/Footer";
import LoadingPanel from "@components/common/LoadingPanel";

import "./Sidebar.scss";

const baseClass = "acl-sidebar";

interface IProps {
    className?: string;
    onToggle?: Function;
}

type TState = Record<string, TMenuItem[]>;

const Sidebar: React.FC<IProps> = () => {
    const [uiContext, _setUiContext] = useUIContext();
    const [routes, setRoutes] = useState<TState>(null);

    useEffect(() => {
        import("@src/common/routes").then((module: any) => setRoutes(module.routes)); // eslint-disable-line
    }, []);

    return (
        <aside className={`${baseClass}`} aria-expanded={uiContext.isSidebarOpen}>
            <Hamburger className={`${baseClass}__toggle`} />
            <nav className={`${baseClass}__menu`} data-testid="nav">
                {routes ? (
                    <>
                        <MenuList list={routes.myAcolin} headline="My Acolin" />
                        <MenuList list={routes.file} headline="File Exchange" />
                        <MenuList list={routes.account} headline="Account" />
                        <MenuList list={routes.help} headline="Help" />
                    </>
                ) : (
                    <LoadingPanel />
                )}
            </nav>
            <Footer />
        </aside>
    );
};

export default Sidebar;
