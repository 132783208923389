import React, { FunctionComponent } from "react";

import logoUrl from "@images/favicon.png";
import Link from "@components/common/Link";

import "./Footer.scss";

const baseClass = "acl-footer";

const Footer: FunctionComponent = () => (
    <footer className={baseClass}>
        <img className={`${baseClass}__logo`} src={logoUrl} alt="Acolin" />
        <address>
            Acolin Fund Services AG
            <br />
            Maintower
            <br />
            Thurgauerstrasse 36/38
            <br />
            8050 Zurich, Switzerland
        </address>
        <nav className={`${baseClass}__links`}>
            <Link to="https://acolin.com/imprint" target="_blank" className={`${baseClass}__link`}>
                Imprint
            </Link>
            <Link to="https://acolin.com/privacy" target="_blank" className={`${baseClass}__link`}>
                Privacy Policy
            </Link>
            <Link to="./release-notes">Release Notes</Link>
        </nav>
    </footer>
);

export default React.memo(Footer);
