import React, { useContext, useState, useCallback, ReactNode } from "react";
import { NotificationGroup, Notification } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

import "./NotificationContext.scss";

interface INotification {
    message: string | React.ReactNode;
    timeout?: number;
    type?: "none" | "success" | "warning" | "error";
    onHide?: () => void;
    onClose?: () => void;
    onTimeout?: () => void;
}

type ContextValue = {
    sendNotification: (notification: INotification) => void;
    clearNotifications: () => void;
};

const initialValue: ContextValue = {
    sendNotification: (): void => {},
    clearNotifications: (): void => {},
};

const noop = (): void => {};

export const NotificationContext = React.createContext<ContextValue>(initialValue);
NotificationContext.displayName = "NotificationContext";
interface Props {
    children?: ReactNode;
}

export const NotificationContextProvider: React.FC<Props> = ({ children }) => {
    const [notifications, setNotifications] = useState<INotification[]>([]);

    function clearNotifications(): void {
        setNotifications([]);
    }

    function dismissNotification(dismissed: INotification): void {
        setNotifications(notifications => notifications.filter(notification => notification !== dismissed));
    }

    function sendNotification(notification: INotification): void {
        setNotifications(n => [...n, notification]);

        const { timeout, onHide = noop, onTimeout = noop } = notification;

        if (timeout) {
            setTimeout(() => {
                onTimeout();
                onHide();
                dismissNotification(notification);
            }, timeout);
        }
    }

    const handleClose = useCallback(
        (notification: INotification) => (): void => {
            const { onHide = noop, onClose = noop } = notification;
            onClose();
            onHide();
            dismissNotification(notification);
        },
        [notifications],
    );

    return (
        <NotificationContext.Provider value={{ sendNotification, clearNotifications }}>
            {children}
            <NotificationGroup className="acl-notification-group">
                {notifications.map((n, i) => (
                    <Fade key={`${i}-${n.message.toString()}`} exit={true}>
                        <Notification type={{ style: n.type, icon: true }} closable onClose={handleClose(n)}>
                            {typeof n.message !== "string" ? (
                                n.message
                            ) : (
                                <div className="acl-notification-message" dangerouslySetInnerHTML={{ __html: n.message }} />
                            )}
                        </Notification>
                    </Fade>
                ))}
            </NotificationGroup>
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = (): ContextValue => useContext(NotificationContext);
