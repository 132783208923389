import React, { useState, Dispatch, SetStateAction, useContext, useMemo, useEffect, ReactNode } from "react";

type ContextValue = [boolean, Dispatch<SetStateAction<boolean>>];
const initialValue: ContextValue = [true, (): void => {}];

export const InactiveUsersContext = React.createContext<ContextValue>(initialValue);
InactiveUsersContext.displayName = "InactiveUsersContext";

const getInitialState = (): boolean => (localStorage.getItem("showInactiveUsers") === "false" ? false : true);

interface Props {
    children?: ReactNode;
}

export const InactiveUsersContextProvider: React.FC<Props> = ({ children }) => {
    const [appUser, setAppUser] = useState<boolean>(getInitialState);

    useEffect(() => {
        localStorage.setItem("showInactiveUsers", JSON.stringify(appUser));
    }, [appUser]);

    const contextValue = useMemo<ContextValue>(() => [appUser, setAppUser], [appUser]);

    return <InactiveUsersContext.Provider value={contextValue}>{children}</InactiveUsersContext.Provider>;
};

export const useInactiveUsersContext = (): ContextValue => useContext(InactiveUsersContext);
