import React, { useState, useEffect } from "react";
import classNames from "classnames";

import { Grid, GridColumn, GridCellProps, GridHeaderCellProps } from "@progress/kendo-react-grid";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Tooltip } from "react-tooltip";
import { Button } from "@progress/kendo-react-buttons";

import { Icon } from "@components/common";
import { checkFirstLetterSpace } from "@src/common/util";

import { Vendor } from "../../types";

const baseClass = "acl-vendor-form";

interface ICheckoxProps {
    value: boolean;
    onCheckChange(val): void;
}

interface IProps {
    vendors: Array<Vendor>;
    shouldAddVendors?: boolean;
    setChangedVendors?(changed: Array<Vendor>): void;
}

const CheckboxComponent: React.FC<ICheckoxProps> = ({ value, onCheckChange }) => {
    const [checked, setChecked] = React.useState(value);

    const handleChange = (event): void => {
        setChecked(event.value);
        onCheckChange(event.value);
    };
    return <Checkbox value={checked} onChange={handleChange} />;
};

const VendorNameCell: React.FC<GridCellProps> = ({ dataItem }) => <td className={dataItem.vendors && `${baseClass}__vendor-group`}>{dataItem.name}</td>;

const FormGrid: React.FC<IProps> = ({ vendors, shouldAddVendors, setChangedVendors }) => {
    const [vendorsList, setVendorsList] = useState(vendors);
    const [newVendor, setNewVendor] = useState<string>(undefined);

    const checkboxHandler = (vendorName, propName, val): void => {
        const objIndex = vendorsList.findIndex(obj => obj.name === vendorName);
        const obj = { ...vendorsList[objIndex] };
        obj[propName] = val;
        const newList = vendorsList.map((vendor, i) => (i === objIndex ? obj : vendor));
        setVendorsList(newList);
    };

    const checkIsButtonDisabled = (): boolean => !newVendor || checkFirstLetterSpace(newVendor) || vendorsList.some(e => e.name === newVendor);

    const handleAddVendor = (): void => {
        if (checkIsButtonDisabled()) {
            return;
        }
        setVendorsList([...vendorsList, { name: newVendor, static: false, regulatory: false, esg: false, active: false }]);
        setNewVendor("");
    };

    const handleRemoveVendor = (name): void => {
        setVendorsList(vendorsList.filter(element => element.name !== name));
    };

    const CheckboxCell: React.FC<GridCellProps> = ({ dataItem, field }) => {
        const originalVendorState = vendors.find(vendor => vendor.name === dataItem.name)?.[field];
        const class1 = `${baseClass}__checkbox-cell`;
        const class2 = !originalVendorState || shouldAddVendors ? `${baseClass}__checkbox-new` : "";
        const checkboxClasses = classNames(class1, class2);

        return (
            <td className={checkboxClasses}>
                {!dataItem.vendors && <CheckboxComponent value={dataItem[field]} onCheckChange={(val): void => checkboxHandler(dataItem.name, field, val)} />}
            </td>
        );
    };

    const RemoveCell: React.FC<GridCellProps> = ({ dataItem }) => (
        <td className={`${baseClass}__remove-cell`}>
            <a data-tooltip-id="statusTooltip" data-tooltip-content="Remove vendor">
                <Icon onClick={(): void => handleRemoveVendor(dataItem.name)} className={`${baseClass}__tooltip`} name="remove-circle" />
            </a>
        </td>
    );

    const HeaderCell: React.FC<GridHeaderCellProps> = ({ title }) => <div className={`${baseClass}__header-cell`}>{title}</div>;

    const columns = [
        { field: "name", title: "Name", cell: VendorNameCell },
        { field: "static", title: "Static Data\n(AMT / BDT)", headerCell: HeaderCell, cell: CheckboxCell, width: 150 },
        { field: "regulatory", title: "Regulatory Data\n(EMT/EPT)", headerCell: HeaderCell, cell: CheckboxCell, width: 150 },
        { field: "esg", title: "ESG Data\n(EET)", headerCell: HeaderCell, cell: CheckboxCell, width: 150 },
        { field: "", title: "", cell: RemoveCell, width: 45 },
    ];

    useEffect(() => {
        setChangedVendors(vendorsList);
    }, [vendorsList]);

    return (
        <>
            {vendorsList.length > 0 && (
                <>
                    {shouldAddVendors && <h4>New Vendors</h4>}
                    <Grid data={vendorsList} scrollable="none">
                        {columns.map(column => (
                            <GridColumn key={column.title} field={column.field} {...column} />
                        ))}
                    </Grid>
                </>
            )}
            {shouldAddVendors && (
                <div className={`${baseClass}__new-vendor-form`}>
                    <h4>Add Vendor</h4>
                    <Input
                        className={`${baseClass}__new-vendor-input`}
                        type="text"
                        placeholder="Enter vendor name"
                        value={newVendor}
                        onChange={(e): void => setNewVendor(e.target.value as string)}
                        onKeyDown={(e): void => {
                            e.key === "Enter" && handleAddVendor();
                        }}
                    />
                    <Button themeColor="primary" onClick={handleAddVendor} disabled={checkIsButtonDisabled()}>
                        Add Vendor
                    </Button>
                </div>
            )}
            <Tooltip id="statusTooltip" place="left" delayShow={100} className={`${baseClass}__tooltipContent`} />
        </>
    );
};

export default FormGrid;
