import React, { useContext, ReactNode, useState, SetStateAction, Dispatch } from "react";

interface Props {
    children?: ReactNode;
}

const initialValue = null;
type ContextValue = [string, Dispatch<SetStateAction<string>>];

export const IncomingPathContext = React.createContext<ContextValue>(initialValue);
IncomingPathContext.displayName = "IncomingPathContext";

export const IncomingPathContextProvider: React.FC<Props> = ({ children }) => {
    const [incomingPath, setIncomingPath] = useState(initialValue);
    return <IncomingPathContext.Provider value={[incomingPath, setIncomingPath]}>{children}</IncomingPathContext.Provider>;
};

export const useIncomingPathContext = (): ContextValue => useContext(IncomingPathContext);
