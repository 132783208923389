import React, { createContext, useState, Dispatch, SetStateAction, useContext, useMemo, ReactNode } from "react";

type UIState = {
    isSidebarOpen: boolean;
};

type ContextValue = [UIState, Dispatch<SetStateAction<UIState>>];

const initialValue: ContextValue = [{ isSidebarOpen: false }, (): void => {}];

const initialState: UIState = {
    isSidebarOpen: false,
};

export const UIContext = createContext<ContextValue>(initialValue);
UIContext.displayName = "UIContext";
interface Props {
    children?: ReactNode;
}

export const UIContextProvider: React.FC<Props> = ({ children }) => {
    const [uiState, setUIState] = useState<UIState>(initialState);

    const contextValue = useMemo<ContextValue>(() => [uiState, setUIState], [uiState]);

    return <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>;
};

export const useUIContext = (): ContextValue => useContext(UIContext);
