import React from "react";

const LoadingPanel: React.FC<{}> = () => (
    <div className="k-loading-mask" data-testid="loader">
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);

export default LoadingPanel;
