import React, { useState, useEffect, useReducer } from "react";
import { publish } from "pubsub-js";
import isEmpty from "lodash/isEmpty";

import { TreeList, TreeListExpandChangeEvent } from "@progress/kendo-react-treelist";
import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

import axios, { AxiosResponse } from "@src/common/http";
import { API_ENDPOINT } from "@src/common/config";
import { useAppUserContext } from "@src/common/Context";
import { getFilenameFromContentDisposition } from "@src/common/util";
import LoadingPanel from "@components/common/LoadingPanel";
import { Icon } from "@components/common";

import { OverviewReport } from "../types";
import DocumentsRow from "./DocumentsRow";
import "./StatusOverviewReport.scss";
import { errors } from "@src/common/errors";
import { PSChannel } from "@src/common/types";

interface IProps {
    onClose(event: DialogCloseEvent): void;
}

interface IState {
    isLoading: boolean;
    isDownloading: boolean;
    error: string;
    report: OverviewReport;
}

const initialState: IState = {
    isLoading: true,
    isDownloading: false,
    error: "",
    report: null,
};

const baseClass = "acl-overview-report";

// export const getSOReport = (id: number): Promise<AxiosResponse> => axios.get(`/portal/status-overview-report?companyId=${id}`);
export const getSOReport = (id: number): Promise<AxiosResponse> => axios.get(`/status-overview-report?companyId=${id}`);

const StatusOverviewReport: React.FC<IProps> = ({ onClose = (): void => {} }) => {
    const [state, setState] = useState<IState>(initialState);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [appUser, _setAppUser] = useAppUserContext();

    const getReport = async (): Promise<void> => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }

        const { status, data } = await getSOReport(appUser.companyId);

        if (status !== 200) {
            setState({ ...state, isLoading: false });
            return;
        }

        setState({ ...state, isLoading: false, report: data });
    };

    const onCloseDialog = (event): void => onClose(event);

    const handleDownload = (): void => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }

        setState({ ...state, isDownloading: true });

        axios
            // .get(`${API_ENDPOINT}/portal/status-overview-report?companyId=${appUser.companyId}&name=${appUser.companyName}&download`, { responseType: "blob" })
            .get(`${API_ENDPOINT}/status-overview-report?companyId=${appUser.companyId}&name=${appUser.companyName}&download`, { responseType: "blob" })
            .then(response => {
                if (response.status !== 200) {
                    setState({ ...state, isDownloading: false });
                    return;
                }
                // Get original file name from header
                const header = response.headers["content-disposition"];
                const filename = getFilenameFromContentDisposition(header, "status-overview-report.xlsx");

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                setState({ ...state, isDownloading: false });
            })
            .catch(error => {
                setState({ ...state, isDownloading: false });
                console.error(error);
            });
    };

    useEffect(() => {
        getReport();
    }, []);

    const handleExpandChange = (event: TreeListExpandChangeEvent): void => {
        event.dataItem.expanded = !event.dataItem.expanded;
        forceUpdate();
    };

    const countries = state.report;

    return (
        <Dialog className={`${baseClass}`} title="Status Overview Report" onClose={onCloseDialog}>
            <div className={`${baseClass}__content`}>
                {countries &&
                    countries.map(({ country, items }) => (
                        <section key={country} className={`${baseClass}__country`}>
                            <h3>{country}</h3>
                            {items && (
                                <TreeList
                                    data={items}
                                    subItemsField={"items"}
                                    expandField={"expanded"}
                                    onExpandChange={handleExpandChange}
                                    columns={[{ field: "name", title: "Fund", expandable: true }]}
                                    row={DocumentsRow}
                                    scrollable="none"
                                    className={`${baseClass}__tree-list`}
                                />
                            )}
                        </section>
                    ))}
                {state.isLoading && <LoadingPanel />}
            </div>
            <footer className={`${baseClass}__actions`}>
                <Button onClick={handleDownload} disabled={isEmpty(state.report) || state.isDownloading}>
                    {state.isDownloading ? (
                        <>
                            <Icon name="loading" spacing="right" /> Downloading
                        </>
                    ) : (
                        "Download"
                    )}
                </Button>
                <Button themeColor="primary" onClick={onCloseDialog}>
                    Close
                </Button>
            </footer>
        </Dialog>
    );
};

export default StatusOverviewReport;
