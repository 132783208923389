import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import Layout from "@components/containers/Layout";
import { Header, MobileDisclaimer, Sidebar } from "@components/common";
import Fallback from "@pages/Fallback";

import "./PageLayout.scss";

const baseClass = "acl-layout--page";

interface Props {
    children?: ReactNode;
}

const PageLayout: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const handleReset = (): void => navigate("/home");

    return (
        <Layout className={`${baseClass}`}>
            <Header />
            <Sidebar />
            <ErrorBoundary FallbackComponent={Fallback} onReset={handleReset}>
                {children}
            </ErrorBoundary>
            <MobileDisclaimer />
        </Layout>
    );
};

export default PageLayout;
