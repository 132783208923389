import { useEffect } from "react";
import { publish } from "pubsub-js";
import { useNavigate, useLocation } from "react-router-dom";
import { setTokenExpiry } from "@src/common/token";
import axios from "@src/common/http";
import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { PSChannel, fallbackUser } from "@src/common/types";
import { clearGQLCache } from "@src/common/Context/GQLContext/GQLContext";

const Logout = (): React.ReactElement => {
    const navigate = useNavigate();
    const [_appUserData, setAppUserData] = useAppUserContext();
    const { clearNotifications } = useNotificationContext();
    const useQuerySearch = (): URLSearchParams => new URLSearchParams(useLocation().search);
    const query = useQuerySearch();

    useEffect(() => {
        const tokenExpiredUrl = query.get("tokenExpired");
        setTokenExpiry("");
        setAppUserData(fallbackUser);
        clearNotifications();
        localStorage.removeItem("authToken");
        clearGQLCache().finally(() => {
            navigate("/sign-in");
            if (tokenExpiredUrl) {
                publish(PSChannel.Error, "Session has expired. Please log in again.");
            }
        });
    }, []);

    return null;
};

export default Logout;
