import React, { useEffect } from "react";
import { publish } from "pubsub-js";
import Page, { Title } from "@components/containers/Page";
import NotificationList from "@components/common/Notifications";

import "./Notifications.scss";
import { useAppUserContext } from "@src/common/Context";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_NOTIFICATIONS } from "@src/common/graphql";
import { errors } from "@src/common/errors";
import { PSChannel } from "@src/common/types";

const baseClass = "acl-page-notifications";

const Notifications: React.FC<{}> = () => {
    const [appUser] = useAppUserContext();

    const [getNotifications, { loading, error, data }] = useLazyQuery(GET_NOTIFICATIONS, {
        variables: { companyId: appUser.companyId },
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.api.MISSING_COMPANY_ID);
            return;
        }
        getNotifications().catch(error => console.error(error));
    }, []);

    const notifications = data?.notifications.map(notification => {
        const { id, subject, message, overview, releaseDate: date, attachmentId, attachmentName } = notification;
        return { id, subject, message, overview, date, attachmentId, attachmentName };
    });

    return (
        <Page className={baseClass}>
            <Title>Notifications</Title>
            <NotificationList loading={loading} notifications={notifications} className={`acl-widget ${baseClass}__messages`} pageable={true} />
        </Page>
    );
};

export default Notifications;
